<template>
  <div class="flex flex-col">
    <div class="wc-page-container text-body-2 mt-12 w-full">
      <div class="mb-8 flex justify-start">
        <ButtonArrowCTA
          direction="backward"
          path="/marketplace/portfolios"
          button-classes="text-blue-70 fill-blue-70 hover:fill-highlight ph-no-capture"
          @click="captureAllPortfoliosEvent">
          All Portfolios
        </ButtonArrowCTA>
      </div>

      <section class="flex w-full flex-wrap items-start gap-5 md:flex-nowrap">
        <div class="order-2 md:basis-1/2">
          <h2 class="text-heading-4 mb-8 mt-4 md:mb-12 md:mt-0">{{ portfolio.name }}</h2>

          <div class="my-6 md:my-8">
            <h4 class="text-subheading-1">Summary</h4>
            <div class="mt-3">
              <p class="whitespace-pre-line">
                {{ portfolio.longDescription }}
              </p>
            </div>
          </div>

          <div class="w-full rounded-lg bg-lilac-100">
            <div v-if="!procurementFormIsVisible" class="p-4">
              <div>
                <div class="flex items-center justify-between gap-4">
                  <div class="text-overline-1 inline-block whitespace-nowrap rounded-lg bg-highlight-yellow px-3 py-1 font-[12px] text-neutral-99">
                    {{ portfolioStatus }}
                  </div>
                  <div class="text-body-3 text-right">
                    <span class="text-body-3-strong">{{ portfolio.quantitySold }} {{ Portfolio.Units(portfolio) }}</span> purchased of
                    {{ portfolio.quantityTotal }}&nbsp;{{ Portfolio.Units(portfolio) }}
                    available
                  </div>
                </div>
                <div class="mt-4 h-4 w-full rounded-lg bg-white">
                  <div
                    :style="{ width: `${(portfolio.quantitySold / portfolio.quantityTotal) * 100}%` }"
                    class="h-full min-w-8 rounded-lg bg-blue-70"></div>
                </div>
              </div>
              <div class="mt-10 flex flex-col items-center gap-8 text-center md:flex-row md:text-left">
                <div>
                  <h4 class="text-overline-1">Total portfolio impact</h4>
                  <div class="mt-3">{{ portfolio.quantityTotal }} {{ Portfolio.Units(portfolio) }}</div>
                </div>
                <div v-if="Portfolio.Units(portfolio) === 'MWh'">
                  <h4 class="text-overline-1">Emissions Impact</h4>
                  <div class="mt-3">{{ Portfolio.EmissionsImpact(portfolio) }}</div>
                </div>
                <div>
                  <h4 class="text-overline-1">Price</h4>
                  <div class="mt-3">{{ Portfolio.PriceFormatted(portfolio) }}/{{ Portfolio.Units(portfolio) }}</div>
                </div>
              </div>
              <div
                v-if="
                  !procurementFormIsVisible && portfolio.status == PortfolioStatusType.funding && portfolio.quantitySold < portfolio.quantityTotal
                "
                class="mt-10 flex justify-center">
                <div class="basis-1/2">
                  <ButtonCTA
                    width="full"
                    button-classes="bg-blue-90 text-white hover:bg-blue-40 ph-no-capture"
                    data-cy="portfolio-purchase-button"
                    @click="handleClickPurchase"
                    >Purchase</ButtonCTA
                  >
                </div>
              </div>
            </div>

            <div v-if="procurementFormIsVisible" class="border-blue-90 px-4 pt-8">
              <h3 class="text-heading-5 mb-10 px-5">Your procurement</h3>
              <form class="items center ph-no-capture flex h-full flex-col justify-center" @submit.prevent="submit">
                <div class="border-b border-sagetone px-5 pb-10">
                  <div class="mt-8 flex flex-wrap justify-between gap-4">
                    <fieldset class="-mt-8 shrink-0 grow basis-40">
                      <label class="text-body-3 mb-2 block text-blue-70">Enter amount</label>
                      <div class="relative w-full text-2xl">
                        <input
                          v-model="order.quantity"
                          maxlength="16"
                          type="text"
                          class="text-body-2-strong w-full rounded-md border-sagetone py-4 pl-5 pr-[3.75rem] text-right"
                          data-cy="purchase-amount"
                          @keypress="validateNumber"
                          @click="captureAmountInputEvent" />
                        <span class="text-body-2 pointer-events-none absolute right-1 top-1/2 -translate-y-1/2 text-sagetone">{{
                          Portfolio.Units(order.portfolio)
                        }}</span>
                      </div>
                    </fieldset>
                    <fieldset class="flex grow items-center justify-items-stretch gap-4">
                      <button
                        type="button"
                        class="rounded-lg bg-highlight-frostblue px-5 py-2 text-center font-bold hover:bg-highlight-pastelazure"
                        :class="{ 'opacity-25 hover:bg-highlight-frostblue': maxProcurementAmount < 50 }"
                        :disabled="maxProcurementAmount < 50"
                        @click="setQuantity(50)">
                        50
                      </button>
                      <button
                        type="button"
                        class="rounded-lg bg-highlight-frostblue px-5 py-2 text-center font-bold hover:bg-highlight-pastelazure"
                        :class="{ 'opacity-25 hover:bg-highlight-frostblue': maxProcurementAmount < 100 }"
                        :disabled="maxProcurementAmount < 100"
                        @click="setQuantity(100)">
                        100
                      </button>
                      <button
                        type="button"
                        class="rounded-lg bg-highlight-frostblue px-5 py-2 text-center font-bold hover:bg-highlight-pastelazure"
                        :class="{ 'opacity-25 hover:bg-highlight-frostblue': maxProcurementAmount < 500 }"
                        :disabled="maxProcurementAmount < 500"
                        @click="setQuantity(500)">
                        500
                      </button>
                    </fieldset>
                  </div>
                  <p class="text-caption mt-2">
                    This portfolio is {{ parseFloat(percentageFunded.toFixed(1)) }}% funded. {{ maxProcurementAmount }}
                    {{ Portfolio.Units(order.portfolio) }} still available for purchase.
                  </p>
                </div>
                <div class="text-body-3 border-b border-sagetone px-5 py-6">
                  <p>
                    As energy savings are realized by projects in this portfolio, WattCarbon will issue Energy Attribute Certificates (EACs) to you,
                    until their total value is equivalent to at least {{ order.quantity }} {{ Portfolio.Units(portfolio) }}.
                  </p>
                  <div v-if="Portfolio.Units(portfolio) === 'MWh'" class="mt-6 flex justify-between">
                    <div>Calculated carbon savings</div>
                    <div class="text-right">
                      <strong>{{ orderCalculatedCarbonSavings !== undefined ? orderCalculatedCarbonSavings.toFixed(2) : "" }} tCO2</strong> avoided
                    </div>
                  </div>
                  <div class="mt-6 flex justify-between">
                    <div>Price</div>
                    <div>
                      <strong>{{ Portfolio.PriceFormatted(portfolio) }}</strong> / {{ Portfolio.Units(portfolio) }}
                    </div>
                  </div>
                </div>
                <div class="flex justify-between px-5 pt-8">
                  <div class="text-body-3-strong">Total</div>
                  <div class="text-body-1-strong">
                    {{ Order.TotalFormatted(order).toLocaleString() }}
                  </div>
                </div>
                <div class="my-10 flex justify-center">
                  <div class="basis-1/2">
                    <ButtonCTA
                      width="full"
                      button-classes="bg-blue-90 text-white hover:bg-blue-40"
                      type="submit"
                      :is-disabled="!order.quantity || order.quantity < 1 || order.quantity > maxProcurementAmount"
                      data-cy="portfolio-purchase-continue"
                      >Continue</ButtonCTA
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="order-1 basis-full bg-neutral-20 md:order-3 md:basis-1/2">
          <div class="h-[240px] w-full bg-neutral-20 md:aspect-square md:h-auto">
            <img :alt="portfolio.name" class="size-full object-cover" :src="Portfolio.Image(portfolio)" />
          </div>
        </div>
      </section>

      <section class="mb-12 mt-4 grid grid-cols-6 flex-wrap gap-5 md:mt-10 md:grid-cols-12">
        <div v-if="portfolio.locationDescription" class="col-span-2 mt-4">
          <h5 class="text-overline-1">Location</h5>
          <div class="mt-3">{{ portfolio.locationDescription }}</div>
        </div>
        <div class="col-span-2 mt-4">
          <h5 class="text-overline-1">Deployment</h5>
          <div class="mt-3">{{ portfolio.dateRangeDescription }}</div>
        </div>
        <div class="col-span-2 mt-4">
          <h5 class="text-overline-1">Time of day</h5>
          <div class="mt-3">{{ portfolio.hoursDescription }}</div>
        </div>
        <div class="col-span-2 mt-4">
          <h5 class="text-overline-1">GHG Reporting</h5>
          <div class="mt-3">{{ Portfolio.ScopeFormatted(portfolio) }}</div>
        </div>
        <div class="col-span-2 mt-4">
          <h5 class="text-overline-1">Project Category</h5>
          <div class="mt-3">{{ Portfolio.Category(portfolio) }}</div>
        </div>
        <div class="mt-4" :class="portfolio.locationDescription ? 'col-span-2' : 'col-span-4'">
          <h5 class="text-overline-1">Suppliers</h5>
          <div class="mt-3 flex flex-wrap">
            <span v-for="(supplier, index) in portfolio.suppliers" :key="supplier.accountId" class="ph-no-capture mr-1 whitespace-nowrap">
              <router-link
                v-if="supplier.isListedOnMarketplace"
                :to="{ name: 'wc-supplier', params: { id: supplier.accountId, slug: supplier.slug } }"
                class="underline"
                @click="captureSupplierSelectEvent(supplier.displayName)"
                >{{ supplier.displayName }}</router-link
              ><template v-else>{{ supplier.displayName }}</template
              ><template v-if="index + 1 < portfolio.suppliers.length">,</template>
            </span>
          </div>
        </div>
      </section>

      <section class="wc-homepage-grid text-body-2 border-t border-sagetone py-12">
        <div class="col-span-6 md:col-span-9">
          <WcAboutDemandResponse v-if="portfolio.buyerAgreementTemplate === 'demand_response'" />
          <WcAboutHeatPumps v-if="portfolio.buyerAgreementTemplate === 'electrification'" />
          <WcAboutSolar v-if="portfolio.buyerAgreementTemplate === 'renewables'" />
        </div>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
// Libraries
import { ref, computed } from "vue"
import { useRoute, useRouter } from "vue-router"
import posthog from "posthog-js"
import { setPageTitle, setPageDescription, setPageImage } from "@/metatags"
// Components
import WcAboutSolar from "@/modules/portfolio/components/WcAboutSolar.vue"
import WcAboutHeatPumps from "@/modules/portfolio/components/WcAboutHeatPumps.vue"
import WcAboutDemandResponse from "@/modules/portfolio/components/WcAboutDemandResponse.vue"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import ButtonArrowCTA from "@/components/ui/ButtonArrowCTA.vue"

// Models
import { Order, Portfolio, PortfolioStatusType } from "@common/models/order"
// Services
import { usePortfolioService } from "@/services/service-container"

const router = useRouter()
const route = useRoute()

// PostHog Events
const captureAllPortfoliosEvent = () => posthog.capture("Clicked on the 'All Portfolios' link - Portfolio View")
const captureSupplierSelectEvent = (supplierName: string) => posthog.capture(`Clicked on the ${supplierName} supplier - Portfolio View`)
const capturePurchasePortfolioEvent = () => posthog.capture("Clicked on the 'Purchase' button - Portfolio View")
const captureAmountInputEvent = () => posthog.capture("Clicked on the 'Enter amount' input - Portfolio View")
const captureProcurementAmountButtonEvent = (buttonValue: number) =>
  posthog.capture(`Clicked on the ${buttonValue} procurement button - Portfolio View`)
const captureSubmitEvent = () => posthog.capture("Submitted the 'Portfolio Procurement' form - Portfolio View")

const validateNumber = (evt: any) => {
  evt = evt ? evt : window.event
  const charCode = evt.which ? evt.which : evt.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) || (order.value.quantity.toString().charAt(0) === "0" && charCode === 48)) {
    evt.preventDefault()
  } else {
    return true
  }
}

const portfolioService = usePortfolioService()

const portfolio = ref<Portfolio>(new Portfolio())
const order = ref<Order>(new Order())

const portfolioStatus = computed(() => {
  return portfolio.value.status === "funding" ? "Fund now" : portfolio.value.status
})

const procurementFormIsVisible = ref<boolean>(false)
const handleClickPurchase = () => {
  capturePurchasePortfolioEvent()
  procurementFormIsVisible.value = true
}
const maxProcurementAmount = computed(() => {
  return portfolio.value.quantityTotal - portfolio.value.quantitySold
})
const percentageFunded = computed(() => {
  return (portfolio.value.quantitySold / portfolio.value.quantityTotal) * 100
})
const orderCalculatedCarbonSavings = computed(() => {
  return portfolio.value.estimatedCarbonIntensityTonnesCo2PerMwh
    ? portfolio.value.estimatedCarbonIntensityTonnesCo2PerMwh * order.value.quantity
    : undefined
})

const setQuantity = (amount: number) => {
  order.value.quantity = amount
  captureProcurementAmountButtonEvent(amount)
}

try {
  portfolio.value = await portfolioService.getPortfolio(Number(route.params.id))
  order.value = new Order(portfolio.value)
} catch (error: any) {
  if (error?.message === "Portfolio not found") {
    router.push({ name: "wc-404" })
  } else {
    throw new Error(error) // Throw the error anyway, so we can render it in AppLayout >:D
  }
}

setPageTitle(portfolio.value.name)
setPageDescription(portfolio.value.shortDescription)
setPageImage(Portfolio.Image(portfolio.value))

const submit = async () => {
  captureSubmitEvent()
  await router.push({ name: "wc-portfolio-checkout", query: { id: portfolio.value.id, q: order.value.quantity } })
}
</script>
