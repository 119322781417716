<template>
  <div class="relative mb-1 pb-3 pt-5" :class="{ 'overflow-x-hidden': !gridHasData, 'overflow-x-scroll': gridHasData }">
    <div class="absolute mt-5 bg-white">
      <div v-for="hour in hours" :key="hour" class="hour-label text-caption h-[14px] w-8 whitespace-nowrap pr-1.5 text-right">
        {{ hour }}
      </div>
    </div>
    <div class="ml-8 flex w-full gap-0.5">
      <div v-for="date in dates" :key="date.toString()" class="shrink-0 basis-[105px]">
        <div class="text-caption pb-1 text-sagetone-dark">
          {{ format(date, "LLL Y") }}
        </div>
      </div>
    </div>
    <div
      class="ml-8 flex w-full gap-0.5"
      :class="{ 'bg-carbon': isLoading || totalAvailableQuantity === 0, 'bg-neutral-90': !isLoading && totalAvailableQuantity > 0 }">
      <div v-for="date in dates" :key="date.toString()" class="shrink-0 basis-[105px]">
        <div class="h-[334px] bg-carbon" />
      </div>
    </div>
    <Transition name="fade" mode="out-in">
      <div
        v-if="isLoading || totalAvailableQuantity === 0"
        class="absolute left-0 top-5 mt-4 flex h-[334px] w-full items-center justify-center text-center">
        <div class="text-white">
          <template v-if="isLoading">Loading EACs</template>
          <template v-else-if="totalAvailableQuantity === 0"> There are no EACs for this scope.<br />Select another scope above. </template>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue"
import { addMonths, format, isBefore, startOfMonth } from "date-fns"
import type { EacMonthlyBalance, NullableEacRangeSelection } from "@/modules/accounts/eac.state"

const hours = [
  "12 AM",
  "1 AM",
  "2 AM",
  "3 AM",
  "4 AM",
  "5 AM",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
]

const props = defineProps<{
  balances: EacMonthlyBalance[] | null
  isLoading: boolean
  startDate: Date
  endDate: Date
}>()

const selectedEacRange = ref<NullableEacRangeSelection>(null)

function adjustDateRangeToAtLeastOneYear(startDate: Date, endDate: Date) {
  const twelveMonthsLater = addMonths(startDate, 12)

  if (isBefore(endDate, twelveMonthsLater)) {
    return {
      startDate: new Date(startDate),
      endDate: twelveMonthsLater, // Subtract one day to not include the start of the next period
    }
  } else {
    return {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    }
  }
}

const totalAvailableQuantity = computed(() => {
  if (!props.balances) return 0
  return props.balances.reduce((acc, balance) => acc + balance.active, 0)
})

const dates = computed(() => {
  const { startDate, endDate } = adjustDateRangeToAtLeastOneYear(props.startDate, props.endDate)
  const start = startOfMonth(startDate)
  const end = startOfMonth(endDate)

  const result: Date[] = []
  let currentMonth = start

  while (currentMonth < end) {
    result.push(new Date(currentMonth))
    currentMonth = addMonths(currentMonth, 1)
  }

  return result
})

const gridHasData = computed(() => {
  return !props.isLoading && totalAvailableQuantity.value > 0
})

const emit = defineEmits(["eac-range-selected"])

const resetSelection = () => {
  selectedEacRange.value = null
  emit("eac-range-selected", null)
}
defineExpose({ resetSelection })
</script>

<style lang="scss" scoped>
.hour-label {
  @apply text-[9px] leading-[12px] text-hint;
}
</style>
