<template>
  <fieldset :class="props.fieldsetClasses">
    <label v-if="label" class="mb-2 block" :for="name">{{ label }}<span v-if="props.required" class="text-error">*</span></label>
    <input
      :id="name"
      :name="name"
      :placeholder="placeholder"
      :value="modelValue"
      :type="type"
      :autocomplete="autocomplete"
      :class="inputClasses"
      :maxlength="maxLength"
      class="text-body-2 block w-full rounded-md border border-blue-90 px-4 py-3 text-black"
      :required="props.required"
      :[dataAttribute?.name]="dataAttribute?.value"
      @input="handleInput" />
    <div v-if="subtext" class="text-black">{{ subtext }}</div>
    <div v-if="error" class="text-body-3 text-error">{{ error }}</div>
  </fieldset>
</template>

<script lang="ts" setup>
// Custom attribute to be rendered on the input element
// Pass as a prop to hook up testing or analytics
// Usage: <TextInput :data-attribute={name: "data-cy", value: "my-tested-input"} />
interface DataAttribute {
  name: string
  value: string
}

// Defines props using typescript
// https://vuejs.org/guide/typescript/composition-api.html#typing-component-props
interface Props {
  type?: string
  label?: string
  name?: string
  modelValue?: string
  placeholder?: string
  required?: boolean
  isDisabled?: boolean
  subtext?: string
  error?: string
  fieldsetClasses?: string
  inputClasses?: string
  dataAttribute?: DataAttribute
  autocomplete?: string
  maxLength?: string
}

// Default prop values
// https://vuejs.org/guide/typescript/composition-api.html#typing-component-props
const props = withDefaults(defineProps<Props>(), {
  type: "text",
  name: "Name",
  required: false,
  dataAttribute: () => ({ name: "", value: "" } as DataAttribute),
})

// Enables you to use v-model on the component as if it were on the input element
// Usage: <TextInput v-model="myInputValue" />
const emit = defineEmits(["update:modelValue"])
const handleInput = (event: Event) => {
  const target = event?.target as HTMLInputElement
  emit("update:modelValue", target.value)
}
</script>
