<template>
  <h2 class="text-heading-4 mb-4 max-w-3xl break-words">Current Audit Status: {{ asset.auditStatus }}</h2>
  <div v-if="!isLoading && auditTrail !== undefined">
    <div v-if="auditTrail.deviceAuditResults !== undefined && auditTrail.deviceAuditResults.issues.length > 0">
      <h4 class="text-subheading-1 mb-4 mt-12">Device Audit Results</h4>
      <table v-if="auditTrail.deviceAuditResults !== undefined" class="text-body-2 w-full text-left">
        <tr
          v-for="issue in auditTrail.deviceAuditResults.issues"
          :key="issue.auditName.concat(issue.description)"
          class="h-[52px] border-b border-neutral-20 align-middle">
          <th class="w-[334px] min-w-[334px] pr-2 font-normal">{{ issue.auditName }}</th>
          <td class="my-10">{{ issue.description }}</td>
        </tr>
      </table>
    </div>

    <div v-if="auditTrail.timeSeriesAuditResults !== undefined && auditTrail.timeSeriesAuditResults.issues.length > 0">
      <h4 class="text-subheading-1 mb-4 mt-12">Time Series Audit Results</h4>
      <table v-if="auditTrail.timeSeriesAuditResults !== undefined" class="text-body-2 w-full text-left">
        <tr
          v-for="issue in auditTrail.timeSeriesAuditResults.issues"
          :key="issue.auditName.concat(issue.description)"
          class="h-[52px] border-b border-neutral-20 align-middle">
          <th class="w-[334px] min-w-[334px] pr-2 font-normal">{{ issue.auditName }}</th>
          <td class="my-10">{{ issue.description }}</td>
        </tr>
      </table>
    </div>

    <div v-if="auditTrail.deviceSavingsAuditResults !== undefined && auditTrail.deviceSavingsAuditResults.issues.length > 0">
      <h4 class="text-subheading-1 mb-4 mt-12">Device Savings Audit Results</h4>
      <table v-if="auditTrail.deviceSavingsAuditResults !== undefined" class="text-body-2 w-full text-left">
        <tr
          v-for="issue in auditTrail.deviceSavingsAuditResults.issues"
          :key="issue.auditName.concat(issue.description)"
          class="h-[52px] border-b border-neutral-20 align-middle">
          <th class="w-[334px] min-w-[334px] pr-2 font-normal">{{ issue.auditName }}</th>
          <td class="my-10">{{ issue.description }}</td>
        </tr>
      </table>
    </div>
  </div>
  <pre v-if="isLoading">Loading Audit Trail...</pre>
  <pre v-if="hasError">Error retrieving audit trail.</pre>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue"
import { useAssetService } from "@/services/service-container"
import type { Asset } from "@common/models/asset"
import type { DeviceAuditTrail } from "@common/models/audit"

// This emit is not implemented, but defined here in order to follow the
// interface between AdminAssetView and its tabs
defineEmits(["on-asset-update-state"])

const assetService = useAssetService()

const { asset } = defineProps<{ asset: Asset }>()
const hasError = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const auditTrail = ref<DeviceAuditTrail>()

onBeforeMount(() => {
  loadAuditTrail()
})

const loadAuditTrail = async () => {
  isLoading.value = true
  try {
    auditTrail.value = await assetService.getAssetsAuditTrail(asset.id)
  } catch (error) {
    hasError.value = true
    console.error("Error loading audit trail", error)
  }
  isLoading.value = false
}
</script>
