<template>
  <router-link :to="portfolioUrl" data-cy="portfolio-preview-card">
    <div
      class="flex h-full flex-col justify-between rounded-md bg-blue-90 px-5 pb-5 pt-7 text-left text-white transition-colors hover:bg-blue-99 md:pb-8 md:pt-10">
      <div>
        <div class="flex items-center justify-between gap-4">
          <div class="text-subheading-1 text-highlight-frostblue">{{ props.portfolio.dateRangeDescription }}</div>
        </div>
        <h5 class="text-heading-5 mt-4">{{ props.portfolio.name }}</h5>
        <p class="text-body-2 mt-4 text-highlight-frostblue md:mt-6">{{ props.portfolio.shortDescription }}</p>
      </div>
      <div>
        <div class="mt-4 h-[180px] w-full bg-blue-70 md:mt-6 md:h-[220px]">
          <img class="size-full object-cover" :src="Portfolio.Image(portfolio)" alt="" />
        </div>
        <div class="mt-4 flex w-full">
          <div class="text-center">
            <div class="text-subheading-1">{{ Portfolio.PriceFormatted(props.portfolio) }} / {{ Portfolio.Units(portfolio) }}</div>
            <div class="text-caption mt-1">Price per impact</div>
          </div>
        </div>
        <div class="mt-4 flex justify-end">
          <ButtonArrowCTA :path="portfolioUrl" button-classes="text-white fill-white hover:fill-highlight-yellow">See Portfolio</ButtonArrowCTA>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script lang="ts" setup>
// Libraries
import { computed, PropType } from "vue"
import { Portfolio } from "@common/models/order"

// Components
import ButtonArrowCTA from "@/components/ui/ButtonArrowCTA.vue"

const props = defineProps({
  portfolio: { type: Object as PropType<Portfolio>, required: true },
})

const portfolioUrl = computed(() => {
  return {
    name: "wc-portfolio",
    params: { id: props.portfolio.id },
  }
})
</script>
