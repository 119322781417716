<template>
  <WcModal class="retirement-modal" :is-open="isOpen" :header="retireDialogTitle" size="small" @update:is-open="handleUpdateIsOpen">
    <template #panel>
      <div v-if="status === Status.READY || status === Status.PENDING">
        <div class="relative">
          <!-- Make invisible to prevent modal from jumping during request -->
          <div :class="{ invisible: status === Status.PENDING }">
            <p class="text-subheading-1">You are about to retire {{ totalSelectedActiveQuantity.toLocaleString() }} Wh of EACs.</p>
            <p class="text-body-1 mt-2">Once an EAC is retired, it can no longer be transferred to another owner. You cannot undo this action.</p>
            <p class="text-body-1 mt-2">
              <LearnMore :href="`${WWW_BASE_URL}/faq#weats-pro`">Learn more about retirement in our FAQ.</LearnMore>
            </p>
            <p class="text-body-1 mt-2">Click "Confirm" to retire the selected EACs.</p>
          </div>
          <!-- Absolute position to prevent modal size from jumping during request -->
          <div v-if="status === Status.PENDING" class="absolute left-0 top-0 w-full">
            <p class="text-subheading-1">Please wait. Your EACs are being retired.</p>
            <p class="text-body-1 mt-4">If you are retiring a large number of EACs, this may take a few minutes.</p>
          </div>
        </div>
      </div>
      <div v-else-if="status === Status.SUCCESS">
        <p class="text-body-1">Your EACs have been successfully retired in your WattCarbon account.</p>
      </div>
      <div v-else-if="status === Status.ERROR">
        <p class="text-body-1 mt-8">An error occurred while retiring your EACs.</p>
      </div>
    </template>
    <template #footer>
      <template v-if="status === Status.READY || status === Status.PENDING">
        <WcButton text="Cancel" class="ml-auto" variant="outlined" :is-disabled="status === Status.PENDING" @click="handleUpdateIsOpen(false)" />
        <WcButton text="Confirm" :is-disabled="status === Status.PENDING" @click="handleSubmit" />
      </template>
      <template v-else>
        <WcButton text="Close" class="ml-auto" variant="outlined" @click="handleUpdateIsOpen(false)" />
      </template>
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { WcButton } from "@/components/button"
import WcModal from "@/components/WcModal.vue"
import { getEnvironment } from "@/environment"
import { EacRetirementItem, EacRetirementCreate } from "@/services/api/registry.model"
import { useRegistryService } from "@/services/service-container"

const QUANTITY_FIELD_NAME = "eacQuantityVolume"

enum Status {
  READY = 0,
  PENDING = 1,
  SUCCESS = 2,
  ERROR = 3,
}

const { WWW_BASE_URL } = getEnvironment()

const registryService = useRegistryService()

const emit = defineEmits(["eacs-retired"])

const props = defineProps<{ selectedEacs: any[]; totalSelectedActiveQuantity: number }>()

const isOpen = ref<boolean>(false)
const status = ref<Status>(Status.READY)

const retireDialogTitle = computed(() => {
  switch (status.value) {
    case Status.PENDING:
      return ""
    case Status.SUCCESS:
      return "Your EACs have been retired"
    case Status.ERROR:
      return "An error occurred"
    default:
      return "Confirm EAC Retirement"
  }
})

const handleUpdateIsOpen = (newValue: boolean) => {
  isOpen.value = newValue
  if (!newValue) {
    resetModal()
  }
}
const resetModal = () => {
  status.value = Status.READY
}

async function resolveWithMinimumTime(promise: Promise<any>, minimumTime = 1000) {
  const delay = new Promise((resolve) => setTimeout(resolve, minimumTime))
  return Promise.all([promise, delay]).then((results) => results[0])
}

const retireSelectedEacs = async () => {
  const certificates = props.selectedEacs
    .filter((eac) => eac["Status"] !== "retired") // only retire EACs that are not already retired
    .map((eac) => {
      return {
        certificateId: eac["eacCompleteStartId"],
        quantity: parseInt(eac[QUANTITY_FIELD_NAME]),
      } as EacRetirementItem
    })
  const payload: EacRetirementCreate = { certificates }
  await registryService.retireEacs(payload)
}

const handleSubmit = async () => {
  status.value = Status.PENDING
  try {
    if (props.totalSelectedActiveQuantity === 0) {
      throw new Error("You have not selected any active EACs to retire.")
    }

    // request takes a minimum of 1 second to show the loading state
    await resolveWithMinimumTime(retireSelectedEacs())
    status.value = Status.SUCCESS
    emit("eacs-retired")
  } catch (error: any) {
    status.value = Status.ERROR
    console.error("Error retiring EACs", error)
  }
}

const openModal = () => handleUpdateIsOpen(true)
const closeModal = () => handleUpdateIsOpen(false)
defineExpose({ openModal, closeModal })
</script>

<style lang="scss">
.retirement-modal {
  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    @apply bg-highlight-pastelazure;
  }
}
</style>
