<template>
  <div>
    <WcTable
      v-if="orderRows.length > 0"
      :data="orderRows"
      :columns="[
        { key: 'date', label: 'Date' },
        { key: 'id', label: 'Order ID' },
        { key: 'account', label: 'Account' },
        { key: 'price', label: 'Price' },
        { key: 'purchasedAmount', label: 'Purchased Amount' },
        { key: 'totalPaid', label: 'Total Paid' },
      ]"
      row-id-field="id"
      table-class="text-body-2 w-main-content mx-auto"
      th-class="text-center pl-4"
      td-class="text-center py-4"
      tr-class="cursor-pointer hover:bg-highlight-yellow transition-colors"
      @row-clicked="handleOrderRowClick">
      <!-- Where necessary, to protect sorting functionality, we give the raw data to the table, and handle formatting in scoped slots -->
      <template #date="{ row }">
        {{ format(new Date(row.date), "LLLL d, y") }}
      </template>
      <template #id="{ row }">
        <span data-cy="orders-table-row">{{ row.id }}</span>
      </template>
      <template #price="{ row }">
        {{ (row.price / 100).toLocaleString("en-US", { style: "currency", currency: "USD" }) }} / {{ UtilityHelper.getShorthandUnit(row.units) }}
      </template>
      <template #purchasedAmount="{ row }"> {{ row.purchasedAmount }} {{ UtilityHelper.getShorthandUnit(row.units) }} </template>
      <template #totalPaid="{ row }">
        {{ (row.totalPaid / 100).toLocaleString("en-US", { style: "currency", currency: "USD" }) }}
      </template>
    </WcTable>
    <div v-else class="wc-page-container">
      <div class="flex flex-col items-center justify-center gap-8 bg-neutral-20 p-8">
        <p class="text-body-2 text-center">You have not placed any orders.</p>
        <ButtonCTA type="router-link" path="/openeac-exchange" theme="light" :is-filled="true">Browse OpenEAC Exchange</ButtonCTA>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Components
import WcTable from "@/components/WcTable/WcTable.vue"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"

// Libraries
import { format } from "date-fns"
import { useRouter } from "vue-router"
import UtilityHelper from "@common/services/utility.helper"
import posthog from "posthog-js"

// Services
import { useOrdersService } from "@/services/service-container"
import { Order } from "@common/models/order"

const router = useRouter()
const ordersService = useOrdersService()

const props = defineProps<{
  portfolioId: number
}>()

let orders = await ordersService.listOrders()
orders = orders.sort((a: Order, b: Order) => new Date(b.updatedTime).getTime() - new Date(a.updatedTime).getTime())
const orderRows = orders
  .filter((o) => o.portfolio.id == props.portfolioId)
  .map((order) => ({
    date: order.updatedTime,
    id: order.id,
    account: order.account.name,
    portfolio: order.portfolio.name,
    price: order.portfolio.pricePenniesUsdPerUnit,
    purchasedAmount: order.quantity,
    totalPaid: order.pricePenniesUsd,
    units: order.portfolio.units, // this data does not have a column in the table, but it's provided for formatting
  }))

const handleOrderRowClick = (order: Order) => {
  captureOrderSelectionEvent(order)
  router.push({ name: "wc-portfolio-confirmation", params: { id: order.id } })
}

// Might be too specific
const captureOrderSelectionEvent = (order: Order) => posthog.capture(`Clicked on the '${order.portfolio}' portfolio - Accounts Orders Table`)
</script>
