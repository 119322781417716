<template>
  <div class="wc-page-container text-body-2 mt-12 w-full max-w-main-content">
    <div class="mb-10 flex justify-start">
      <ButtonArrowCTA
        direction="backward"
        :path="order.portfolio.id ? `/marketplace/portfolios/${order.portfolio.id}` : ''"
        button-classes="text-blue-70 fill-blue-70 hover:fill-highlight ph-no-capture"
        @click="captureBackToPortfolioEvent">
        Back to Portfolio
      </ButtonArrowCTA>
    </div>
    <div class="mt-4 flex w-full flex-wrap items-start justify-between gap-8 md:flex-nowrap">
      <section class="order-2 mb-8 basis-full rounded-lg bg-lilac-100 px-4 py-8 md:mb-0 md:basis-1/2">
        <div class="border-blue-90">
          <h3 class="text-heading-5 mb-10 px-5">Your procurement</h3>
          <div class="mt-6 flex justify-between px-5">
            <div class="text-body-2">Portfolio</div>
            <div class="text-body-2-strong text-right">{{ order.portfolio.name }}</div>
          </div>

          <div v-if="!pageState.isEditMode" class="mt-6 flex justify-between px-5 pb-6">
            <div class="text-body-2">Amount</div>
            <div class="text-right">
              <div class="text-body-2-strong">{{ order.quantity }} {{ Portfolio.Units(order.portfolio) }}</div>
              <button v-if="store.isLoggedIn" class="text-body-2 ph-no-capture underline hover:text-blue-40" @click="setPageToEdit">Edit</button>
              <p v-else class="text-body-2">Sign in to change amount</p>
            </div>
          </div>

          <form v-else class="ph-no-capture size-full px-5 py-6" @submit.prevent="updatePaymentIntent">
            <div class="mt-8 flex flex-wrap justify-between gap-4">
              <fieldset class="-mt-8 shrink-0 grow basis-40">
                <label class="text-body-2 mb-2 block text-blue-70">Enter amount</label>
                <div class="relative w-full text-2xl">
                  <input
                    v-model="order.quantity"
                    maxlength="16"
                    type="text"
                    class="text-body-2-strong w-full rounded-md border-sagetone py-4 pl-5 pr-[3.75rem] text-right"
                    @keypress="validateNumber"
                    @click="captureEditProcurementInputEvent" />
                  <span class="text-body-2 pointer-events-none absolute right-1 top-1/2 -translate-y-1/2 text-sagetone">{{
                    Portfolio.Units(order.portfolio)
                  }}</span>
                </div>
              </fieldset>
              <fieldset class="flex grow items-center justify-items-stretch gap-4">
                <button
                  type="button"
                  class="rounded-lg bg-highlight-frostblue px-5 py-2 text-center font-bold hover:bg-highlight-pastelazure"
                  :class="{ 'opacity-25 hover:bg-highlight-frostblue': maxProcurementAmount < 50 }"
                  :disabled="maxProcurementAmount < 50"
                  @click="setQuantity(50)">
                  50
                </button>
                <button
                  type="button"
                  class="rounded-lg bg-highlight-frostblue px-5 py-2 text-center font-bold hover:bg-highlight-pastelazure"
                  :class="{ 'opacity-25 hover:bg-highlight-frostblue': maxProcurementAmount < 100 }"
                  :disabled="maxProcurementAmount < 100"
                  @click="setQuantity(100)">
                  100
                </button>
                <button
                  type="button"
                  class="rounded-lg bg-highlight-frostblue px-5 py-2 text-center font-bold hover:bg-highlight-pastelazure"
                  :class="{ 'opacity-25 hover:bg-highlight-frostblue': maxProcurementAmount < 500 }"
                  :disabled="maxProcurementAmount < 500"
                  @click="setQuantity(500)">
                  500
                </button>
              </fieldset>
            </div>
            <p class="text-caption mt-2">
              This portfolio is {{ parseFloat(Order.PercentageFunded(order).toFixed(1)) }}% funded. {{ maxProcurementAmount }}
              {{ Portfolio.Units(order.portfolio) }} still available for purchase.
            </p>

            <div class="mt-4 flex">
              <ButtonCTA
                is-filled
                theme="light"
                :is-disabled="!order.quantity || order.quantity < 1 || order.quantity > maxProcurementAmount"
                type="submit"
                >Update Order</ButtonCTA
              >
            </div>
          </form>

          <div class="border-y border-sagetone px-5 py-6">
            <p>
              As energy savings are realized by projects in this portfolio, WattCarbon will issue Energy Attribute Certificates (EACs) to you, until
              their total value is equivalent to at least {{ order.quantity }} {{ Portfolio.Units(order.portfolio) }}.
            </p>
            <div v-if="Portfolio.Units(order.portfolio) === 'MWh'" class="mt-6 flex justify-between">
              <div>Calculated carbon savings</div>
              <div class="text-right">
                <strong>{{ Order.CarbonSavings(order).toFixed(2) }} tCO2</strong> avoided
              </div>
            </div>
            <div class="mt-6 flex justify-between">
              <div>Price</div>
              <div>
                <strong>{{ Portfolio.PriceFormatted(order.portfolio) }}</strong> / {{ Portfolio.Units(order.portfolio) }}
              </div>
            </div>
            <div v-if="!pageState.showPromo && order.adjustmentPenniesUsd > 0" class="mt-6">
              <div class="flex justify-between">
                <div>Subtotal</div>
                <div>
                  <strong>{{ Order.PriceFormatted(order) }}</strong>
                </div>
              </div>
              <div class="flex justify-between">
                <div>
                  Code: <strong>{{ order.promoCode }}</strong>
                  <button v-if="!pageState.showPromo" class="ph-no-capture pl-4 text-left text-sm underline" @click="showPromo">Edit</button>
                </div>
                <div>
                  {{ Order.AdjustmentFormatted(order) }}
                </div>
              </div>
            </div>
            <button
              v-if="store.isLoggedIn && !pageState.showPromo && order.adjustmentPenniesUsd === 0"
              class="text-body-2 ph-no-capture mt-6 text-left underline hover:text-blue-40"
              @click="showPromo">
              Add Promo Code
            </button>
            <form v-if="pageState.showPromo" class="ph-no-capture mt-6 flex flex-col gap-4" @submit.prevent="updatePaymentIntent">
              <div>
                <TextInput
                  v-model="order.promoCode"
                  label="Promo code"
                  type="text"
                  name="promo-code"
                  class="shrink grow basis-[100px]"
                  @click="capturePromoCodeInputEvent" />
              </div>
              <div class="flex gap-4">
                <ButtonCTA theme="light" @click="hidePromo">Cancel</ButtonCTA>
                <ButtonCTA is-filled theme="light" type="submit" @click="captureApplyCodeEvent">Apply Code</ButtonCTA>
              </div>
            </form>
            <div v-if="pageState.error" class="mb-2 text-error">{{ pageState.error }}</div>
          </div>
          <div class="flex justify-between px-5 pt-8">
            <div class="text-body-2-strong">Total</div>
            <div class="text-body-1-strong">
              {{ Order.TotalFormatted(order).toLocaleString() }}
            </div>
          </div>
        </div>
      </section>
      <section
        class="shrink grow basis-full md:basis-1/2"
        :class="{ 'order-1 self-center md:order-3': !store.isLoggedIn, 'order-3 self-start': store.isLoggedIn }">
        <div v-if="store.isLoggedIn" ref="paymentFormContainer" class="mb-12 min-h-[400px] px-8 md:mb-0 md:px-16" data-cy="payment-form-container">
          <div v-show="pageState.isLoading" class="h-full">
            <WcLoadingSpinner />
          </div>
          <div v-show="!pageState.isLoading && !hasIntent">
            <h3 class="text-heading-5 mb-4 whitespace-nowrap">Purchaser information</h3>
            <form class="mt-10" @submit.prevent="submitPurchaserInformation">
              <h4 class="text-body-2 text-blue-70">Email address</h4>
              <div class="text-body-2 mt-2">{{ store.auth.user?.username }}</div>
              <h4 class="text-body-2 mt-8 text-blue-70">Purchasing Entity</h4>
              <div class="mt-4">
                <div class="text-body-2">I am purchasing as a:</div>
                <div class="switch-field ph-no-capture">
                  <input
                    id="radio-one"
                    v-model="purchasingEntityType"
                    type="radio"
                    name="switch-one"
                    :value="AccountType.individual"
                    @click="captureEntityEvent(AccountType.individual)" />
                  <label for="radio-one">Individual</label>
                  <input
                    id="radio-two"
                    v-model="purchasingEntityType"
                    type="radio"
                    name="switch-one"
                    :value="AccountType.organization"
                    @click="captureEntityEvent(AccountType.organization)" />
                  <label for="radio-two">Company/Organization</label>
                </div>
                <div v-if="purchasingEntityType == AccountType.organization && organizationAccounts.length" class="ph-no-capture my-6">
                  <div class="text-body-2 text-blue-70">Select an organization</div>
                  <Listbox v-model="selectedAccount" @update:model-value="handleSelectOrganization">
                    <div class="relative mt-1">
                      <ListboxButton
                        class="relative w-full cursor-pointer rounded-md border border-blue-90 bg-white py-3 pl-3 pr-10 text-left focus:outline-none focus-visible:border-highlight focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-highlight">
                        <div v-if="userIsAddingNewOrganization">
                          <span class="block truncate pl-6 font-normal">New organization</span>
                          <span class="absolute inset-y-0 left-0 flex items-center pl-3"><Icon icon="wc-carbon:add" /></span>
                        </div>
                        <span v-else class="block truncate">{{ selectedAccount?.name }}</span>
                        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronIcon class="text-gray-400 size-5" aria-hidden="true" />
                        </span>
                      </ListboxButton>
                      <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <ListboxOptions
                          class="text-body-2 absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
                          <ListboxOption
                            v-for="organization in organizationAccounts"
                            v-slot="{ selected }"
                            :key="organization.id"
                            :value="organization"
                            as="template">
                            <li class="relative cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-neutral-20">
                              <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">{{ organization.name }}</span>
                              <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3"
                                ><Icon icon="wc-carbon:checkmark"
                              /></span>
                            </li>
                          </ListboxOption>
                          <ListboxOption v-slot="{ selected }" :value="{} as Account" as="template">
                            <li class="relative cursor-pointer select-none py-2 pl-10 pr-4 text-blue-70 hover:bg-neutral-20">
                              <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">New organization</span>
                              <span class="text-amber-600 absolute inset-y-0 left-0 flex items-center pl-3"><Icon icon="wc-carbon:add" /></span>
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </transition>
                    </div>
                  </Listbox>
                </div>
                <TextInput
                  v-if="userIsAddingNewOrganization || userIsEditingAccountLegalName"
                  v-model="accountEditLegalName"
                  :label="`Legal name of ${purchasingEntityType}`"
                  type="text"
                  name="legal-full-name"
                  class="ph-no-capture my-6 w-full"
                  required
                  :data-attribute="{ name: 'data-cy', value: 'legal-full-name-input' }"
                  @click="captureNameEditInputEvent" />
                <div v-else class="ph-no-capture my-6">
                  <div class="text-body-2 text-blue-70">Legal name of purchasing {{ purchasingEntityType }}</div>
                  <div class="flex-end flex justify-between">
                    <div class="text-body-2 mt-2">{{ selectedAccount?.name }}</div>
                    <button class="text-body-2 underline" data-cy="edit-legal-name-button" @click="openAccountLegalNameEditor">Edit</button>
                  </div>
                </div>
              </div>
              <ButtonCTA
                width="full"
                button-classes="mt-4 bg-blue-90 text-white hover:bg-blue-40 ph-no-capture"
                :class="{
                  'opacity-25 hover:border-blue-90 hover:bg-blue-90':
                    (userIsAddingNewOrganization || userIsEditingAccountLegalName) && accountEditLegalName === '',
                }"
                :is-disabled="pageState.isEditMode || ((userIsAddingNewOrganization || userIsEditingAccountLegalName) && accountEditLegalName === '')"
                type="submit"
                data-cy="continue-purchaser-information-button"
                @click="captureSubmitPurchaserForm"
                >Continue</ButtonCTA
              >
            </form>
          </div>
          <div v-show="!pageState.isLoading && hasIntent" class="h-full">
            <div class="mb-10 flex flex-col justify-start">
              <h3 class="text-heading-5 mb-4 whitespace-nowrap">Payment details</h3>
              <button class="text-body-2 ph-no-capture flex self-start fill-blue-70 text-blue-70 hover:fill-highlight" @click="backToPurchase">
                <div class="flex">
                  <div>Back to Purchaser Information</div>
                </div>
              </button>
            </div>
            <div class="mt-10">
              <h4 class="text-body-2 text-blue-70">Account</h4>
              <div class="text-body-2 mt-2">{{ emailAddress }}</div>
            </div>
            <div class="mt-10">
              <h4 class="text-body-2 text-blue-70">Full legal name</h4>
              <div class="text-body-2 mt-2">{{ selectedAccount?.name }}</div>
            </div>
            <div class="mt-10">
              <h4 class="text-body-2 text-blue-70">Payment method</h4>
              <form id="payment-form" class="mt-4 flex h-full flex-col justify-evenly" @submit.prevent="pay">
                <div id="payment-element"></div>
                <div id="payment-message" class="hidden"></div>
                <div v-if="!pageState.isEditMode && order.stripePaymentId" class="mb-12 mt-6 flex w-full justify-center">
                  <ButtonCTA
                    width="full"
                    button-classes="bg-blue-90 text-white hover:bg-blue-40 ph-no-capture"
                    :is-disabled="!pageState.canContinueToAgreement"
                    data-cy="continue-to-agreement"
                    @click="openAgreement"
                    >Continue</ButtonCTA
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
        <WcPortfolioLogin v-else :callback-url="callbackUrl" class="pb-12" />
      </section>
      <div v-if="isAgreementVisible" class="fixed inset-0 z-50 flex items-center justify-center p-6">
        <div class="absolute inset-0 z-0 bg-black/50" @click="isAgreementVisible = false"></div>
        <div class="relative z-10 flex max-h-full w-full max-w-[1230px] flex-col rounded-md bg-white">
          <div class="shrink-0 basis-[50px] border-b border-sagetone px-6 pb-4 pt-8">
            <h3 class="text-heading-5 mb-4 whitespace-nowrap">EAC Streaming Agreement</h3>
            <p>Please read and scroll to the bottom to accept the EAC streaming agreement and complete your purchase.</p>
            <button class="text-blue-70 underline" @click="openAgreementInNewWindow">Print agreement</button>
          </div>
          <!-- TODO: Restore the buyerAgreementHtml piece if we use this in the future. It's currently not implemented -->
          <div class="shrink basis-full overflow-y-scroll p-6" data-cy="agreement-container" @scroll="handleScrollAgreementContent"></div>
          <div class="flex shrink-0 basis-[50px] flex-wrap items-stretch justify-center gap-8 border-t border-neutral-30 px-6 py-4">
            <div class="grow basis-[300px]">
              <ButtonCTA width="full" button-classes="border border-blue-90 ph-no-capture" :is-filled="false" @click="closeWithoutAccepting"
                >Close without accepting</ButtonCTA
              >
            </div>
            <div class="grow basis-[300px]">
              <ButtonCTA
                width="full"
                button-classes="bg-blue-90 text-white hover:bg-blue-40 ph-no-capture"
                type="submit"
                :is-disabled="!isAgreementRead"
                data-cy="agree-and-complete-purchase"
                @click="handleClickCompletePurchase"
                >I agree & Complete Purchase</ButtonCTA
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Libraries
import { computed, reactive, ref, watch } from "vue"
import { useRoute } from "vue-router"
import { Icon } from "@iconify/vue"
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue"
import { Stripe } from "@stripe/stripe-js"
import type { StripeElements, StripeElementsOptionsClientSecret, StripePaymentElementOptions } from "@stripe/stripe-js"
import posthog from "posthog-js"

// Components
import WcPortfolioLogin from "@/modules/portfolio/components/WcPortfolioLogin.vue"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import ButtonArrowCTA from "@/components/ui/ButtonArrowCTA.vue"
import TextInput from "@/components/ui/TextInput.vue"
import ChevronIcon from "@/components/icon/ChevronIcon.vue"

// Model
import { Account, AccountType } from "@common/models/models"
import { Order, Portfolio } from "@common/models/order"

// Services
import { useAccountsService, usePaymentService, usePortfolioService, useStripe } from "@/services/service-container"
import { useMainStore } from "@/store"
import { CreatePaymentIntent } from "@/services/api/payment.model"

const route = useRoute()

const validateNumber = (evt: any) => {
  evt = evt ? evt : window.event
  const charCode = evt.which ? evt.which : evt.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) || (order.value.quantity.toString().charAt(0) === "0" && charCode === 48)) {
    evt.preventDefault()
  } else {
    return true
  }
}

// PostHog Events
const captureBackToPortfolioEvent = () => posthog.capture("Clicked on the 'Back to Portfolio' link - Portfolio Checkout View")
const captureEditProcurementEvent = () => posthog.capture("Clicked on the 'Edit' link - procurement form - Portfolio Checkout View")
const capturePromoCodeEvent = () => posthog.capture("Clicked on the 'Add Promo Code' link - Portfolio Checkout View")
const captureEditProcurementInputEvent = () => posthog.capture("Clicked on the 'Enter amount' input")
const captureEditProcurementButtonEvent = (buttonValue: number) =>
  posthog.capture(`Clicked on the ${buttonValue} procurement amount - Portfolio Checkout View`)
const captureUpdateOrderEvent = () => posthog.capture("Clicked on the 'Update Order' button - Portfolio Checkout View")
const capturePromoCodeInputEvent = () => posthog.capture("Clicked on the 'Promo code' input - Portfolio Checkout View")
const captureCancelCodeEvent = () => posthog.capture("Clicked on the 'Cancel' button - promo code - Portfolio Checkout View")
const captureApplyCodeEvent = () => posthog.capture("Clicked on the 'Apply Code' button - Portfolio Checkout View")
const captureEntityEvent = (entity: string) => posthog.capture(`Clicked on the '${entity}' entity tab - Portfolio Checkout View`)
const captureEditNameEvent = () => posthog.capture("Clicked on the 'Edit' legal name link - Portfolio Checkout View")
const captureNameEditInputEvent = () => posthog.capture("Clicked on the 'Legal name of individual' input - Portfolio Checkout View")
const captureSubmitPurchaserForm = () => posthog.capture("Submitted 'Purchaser information' form - Portfolio Checkout View")
const captureOrganizationDropdownEvent = () => posthog.capture("Clicked on the 'Organization' dropdown - Portfolio Checkout View")
const captureBackToPurchaserEvent = () => posthog.capture("Clicked on the 'Back to Purchaser' link - Portfolio Checkout View")
const captureGoToAgreementEvent = () => posthog.capture("Submitted payment details - Portfolio Checkout View")
const capturePrintAgreementEvent = () => posthog.capture("Clicked on 'Print agreement' link - Portfolio Checkout View")
const captureCloseWithoutAcceptingEvent = () => posthog.capture("Clicked on the 'Close Without Accepting' button - Portfolio Checkout View")
const captureCompletePurchaseEvent = () => posthog.capture("Clicked on the 'I Agree & Complete Purchase' button - Portfolio Checkout View")

const order = ref<Order>(new Order(new Portfolio()))
const store = useMainStore()
const accountsService = useAccountsService()
const portfolioService = usePortfolioService()
const paymentService = usePaymentService()

const callbackUrl = computed(() => {
  return encodeURIComponent(`/marketplace/portfolios/checkout?id=${order.value.portfolio.id}&q=${order.value.quantity}`)
})

const displayPurchaserInfo = ref<boolean>(true)
const backToPurchase = () => {
  displayPurchaserInfo.value = true
  captureBackToPurchaserEvent()
}

const hasIntent = computed(() => {
  if (displayPurchaserInfo.value) {
    return false
  } else {
    return order.value.stripePaymentId
  }
})
const emailAddress = ref(store.auth.user?.username)
const pageState = reactive({
  isEditMode: false,
  isLoading: false,
  showPromo: false,
  canContinueToAgreement: false,
  error: null,
})

const setPageToEdit = () => {
  pageState.isEditMode = true
  captureEditProcurementEvent()
}

const showPromo = () => {
  pageState.showPromo = true
  capturePromoCodeEvent()
}

const hidePromo = () => {
  pageState.showPromo = false
  captureCancelCodeEvent()
}

const closeWithoutAccepting = () => {
  isAgreementVisible.value = false
  captureCloseWithoutAcceptingEvent()
}

const maxProcurementAmount = computed(() => {
  return order.value.portfolio.quantityTotal - (order.value.portfolio.quantitySold || 0)
})

const setQuantity = (amount: number) => {
  order.value.quantity = amount
  captureEditProcurementButtonEvent(amount)
}

const paymentFormContainer = ref<HTMLElement | undefined>(undefined)
const isPaymentFormInViewport = () => {
  if (paymentFormContainer.value) {
    paymentFormContainer.value.getBoundingClientRect().top < window.innerHeight
  } else {
    return false
  }
}

const accountEditLegalName = ref<string>("")
const userIsEditingAccountLegalName = ref<boolean>(false)
const userIsAddingNewOrganization = ref<boolean>(false)
const selectedAccount = ref<Account>({} as Account)
const purchasingEntityType = ref<string>("individual")
const individualAccount = computed(() => store.accounts.filter((account) => account.type === AccountType.individual)[0])
const organizationAccounts = computed(() => store.accounts.filter((account) => account.type === AccountType.organization))
const openAccountLegalNameEditor = () => {
  userIsEditingAccountLegalName.value = true
  accountEditLegalName.value = accountHasDefaultName(selectedAccount.value) ? "" : selectedAccount.value.name
  captureEditNameEvent()
}
const closeAccountLegalNameEditor = () => {
  userIsEditingAccountLegalName.value = false
}
const handleSelectOrganization = (selectedValue: any) => {
  if (Object.keys(selectedValue).length === 0) {
    userIsAddingNewOrganization.value = true
  } else {
    userIsAddingNewOrganization.value = false
  }
  captureOrganizationDropdownEvent()
}
const accountHasDefaultName = (account: Account) => {
  return store.auth.user?.username === account?.name
}
watch(purchasingEntityType, (newType) => {
  if (newType === AccountType.individual) {
    selectedAccount.value = individualAccount.value
    userIsAddingNewOrganization.value = false
  } else if (newType === AccountType.organization) {
    if (organizationAccounts.value.length) {
      selectedAccount.value = organizationAccounts.value[0]
    } else {
      userIsAddingNewOrganization.value = true
    }
  }
})

watch(selectedAccount, (newSelectedAccount) => {
  if (accountHasDefaultName(newSelectedAccount)) {
    openAccountLegalNameEditor()
  } else {
    closeAccountLegalNameEditor()
  }
})
const submitPurchaserInformation = async () => {
  if (userIsAddingNewOrganization.value) {
    try {
      if (!emailAddress.value) throw new Error()
      let newAccount = await accountsService.addAccount(emailAddress.value)
      newAccount = await accountsService.updateAccountName(newAccount, accountEditLegalName.value)
      selectedAccount.value = newAccount
    } catch (error) {
      console.error("There was an issue creating a new account.")
    }
  } else if (userIsEditingAccountLegalName.value) {
    try {
      const result = await accountsService.updateAccountName(selectedAccount.value, accountEditLegalName.value)
      selectedAccount.value = result
    } catch (error) {
      console.error("There was an issue updating the account's name.")
    }
  }
  displayPurchaserInfo.value = false
  updatePaymentIntent()
}

// Workaround for issue where quantitySold disappears
watch(
  () => pageState.isEditMode,
  async (isEditMode) => {
    if (isEditMode) {
      const portfolio = await portfolioService.getPortfolio(Number(route.query.id))
      order.value.portfolio.quantitySold = portfolio.quantitySold
    }
  }
)
let elements: StripeElements

const createStripePaymentForm = (clientSecret: string) => {
  if (!stripe) return
  const elementOptions = {
    clientSecret: clientSecret,
    appearance: { theme: "stripe" },
  } as StripeElementsOptionsClientSecret
  elements = stripe.elements(elementOptions)
  const paymentElementOptions = {
    layout: "tabs",
  } as StripePaymentElementOptions
  const stripePaymentElement = elements.create("payment", paymentElementOptions)
  stripePaymentElement.mount("#payment-element")
  // https://stripe.com/docs/js/element/events
  stripePaymentElement.on("change", (event) => {
    pageState.canContinueToAgreement = event.complete
  })
}

const updatePaymentIntent = async () => {
  captureUpdateOrderEvent()
  pageState.isEditMode = false
  pageState.showPromo = false

  if (!store.isLoggedIn || !order.value.quantity) {
    return
  }
  if (!stripe) {
    return
  }
  const intent = {
    id: order.value.stripePaymentId,
    quantity: order.value.quantity,
    description: order.value.portfolio.name,
    orderId: order.value.id,
    portfolioId: order.value.portfolio.id,
    promoCode: order.value.promoCode,
    accountId: selectedAccount.value?.id,
  } as CreatePaymentIntent

  try {
    const paymentIntent = await paymentService.createPaymentIntent(intent)
    const _portfolio = Object.assign(new Portfolio(), order.value.portfolio)
    const _order = Object.assign(new Order(_portfolio), paymentIntent.order)
    if (paymentIntent.clientSecret) {
      createStripePaymentForm(paymentIntent.clientSecret)
      _order.stripePaymentId = paymentIntent.clientSecret.split("_secret")[0]
    }
    if (paymentIntent.buyerAgreementHtml) {
      buyerAgreementHtml.value = paymentIntent.buyerAgreementHtml
    }
    if (paymentIntent.buyerAgreementPreambleHtml) {
      buyerAgreementPreambleHtml.value = paymentIntent.buyerAgreementPreambleHtml
    }
    order.value = _order
    pageState.error = paymentIntent.warning
  } catch (message: any) {
    pageState.error = message
  }
}

const pay = async () => {
  if (!store.isLoggedIn || !order.value.quantity) {
    return
  }
  if (!stripe) {
    return
  }
  pageState.isLoading = true

  try {
    const returnUrl = `${window.location.origin}/marketplace/portfolios/confirmation/${order.value.id}`
    const { error } = await stripe.confirmPayment({
      elements: elements,
      confirmParams: {
        return_url: returnUrl,
        receipt_email: emailAddress.value,
      },
    })
    // on success: redirected to return url. this code won't fire
    if (error.type === "card_error" || error.type === "validation_error") {
      console.error(error.message)
    } else {
      console.error("Unexpected payment error")
    }
  } finally {
    pageState.isLoading = false
  }
}

const buyerAgreementHtml = ref<string>("")
const buyerAgreementPreambleHtml = ref<string>("")
const isAgreementVisible = ref<boolean>(false)
const openAgreement = (event: Event) => {
  event.preventDefault()
  isAgreementVisible.value = true
  captureGoToAgreementEvent()
}
const openAgreementInNewWindow = (event: Event) => {
  event.preventDefault()
  capturePrintAgreementEvent()
  const newWindow = window.open("", "EAC Streaming Agreement") // open new window/tab
  if (newWindow) {
    newWindow.document.write(buyerAgreementPreambleHtml.value)
    newWindow.document.write(buyerAgreementHtml.value)
    newWindow.focus()
    setTimeout(() => {
      newWindow?.print()
    }, 1000)
  } else {
    console.error("Couldn't open agreement in new window")
  }
}
const isAgreementRead = ref<boolean>(false)
const handleScrollAgreementContent = (event: Event) => {
  const target = event.target as HTMLDivElement
  if (target) {
    const clientHeight = target.clientHeight
    const scrollHeight = target.scrollHeight
    const scrollTop = target.scrollTop
    // Add 1 px for firefox error
    if (scrollTop + clientHeight >= scrollHeight - 1) {
      isAgreementRead.value = true
    }
  }
}
const handleClickCompletePurchase = () => {
  captureCompletePurchaseEvent()
  isAgreementVisible.value = false
  pay()
}

const stripe: Stripe | null = await useStripe()
emailAddress.value = store.auth.user?.username
const portfolio = await portfolioService.getPortfolio(Number(route.query.id))
const _order = new Order(portfolio)
if (route.query.q && Number(route.query.q) > 0) {
  _order.quantity = Number(route.query.q)
  pageState.isEditMode = false
}
order.value = _order
await updatePaymentIntent()
if (store.isLoggedIn && !isPaymentFormInViewport()) {
  paymentFormContainer.value?.scrollIntoView(true)
}
selectedAccount.value = individualAccount.value
</script>

<style scoped lang="scss">
#payment-form {
  input {
    border: 1px solid blue;
  }
}

.switch-field {
  @apply inline-flex bg-neutral-20 mt-2 p-2 rounded-lg;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  @apply text-sagetone rounded-md transition-colors text-center py-2 px-4 cursor-pointer text-base;
}

.switch-field input:checked + label {
  @apply bg-white;
}
</style>
