<template>
  <div class="wc-page-container text-body-2 w-full">
    <section class="flex size-full flex-col flex-wrap items-start justify-center gap-5 md:flex-nowrap">
      <div class="flex w-full flex-col items-center">
        <div class="flex flex-col items-center px-4">
          <div class="mb-8 flex justify-start"><h4 class="text-heading-6 font-semibold uppercase">404 Page Not Found</h4></div>
          <div class="mt-6 flex flex-col items-center gap-8 text-center md:flex-row md:text-left">
            <div>
              <h4 class="text-heading-4">Uh-oh! Looks like this isn't a page.</h4>
            </div>
          </div>
          <div class="mt-16 flex justify-center">
            <div>
              <img class="mx-auto block w-[120px]" alt="Buildings" src="https://static.wattcarbon.com/wattcarbon/icons/icon_heat-pump_light.svg" />
              <ButtonCTA width="full" button-classes="bg-blue-90 text-white hover:bg-blue-40 mt-10" @click="goHome()">Go Back Home</ButtonCTA>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
// Libraries
import { useRouter } from "vue-router"
// Components
import ButtonCTA from "@/components/ui/ButtonCTA.vue"

const { push } = useRouter()

const goHome = () => {
  push("/openeac-exchange")
}
</script>

<style scoped lang="scss"></style>
