<template>
  <section class="py-8">
    <h2 class="wc-page-container text-heading-5">Available EACs</h2>
    <MaxHeightContainer :max-height="580" :show-all-text="`Show all ${listingData.length} listings`" show-less-text="Show fewer listings">
      <div class="px-4 md:px-[50px]">
        <WcTable
          :data="listingData"
          :columns="[
            { key: 'id', label: 'ID', tdClass: 'text-center' },
            { key: 'provider', label: 'Grid', tdClass: 'text-center' },
            { key: 'dateRange', label: 'Term', tdClass: 'text-center' },
            {
              key: 'hoursOfDayLocal',
              label: `Hour(s) (${localTZName})`,
              customSort: handleHourColumnSort,
              tdClass: 'text-center',
              thClass: 'w-[76px]',
            },
            {
              key: 'averageCarbonIntensityKgco2PerMwh',
              label: 'Average Carbon Intensity (kgCO2/MWh)',
              tdClass: 'text-center',
              thClass: 'text-center w-[135px]',
            },
            { key: 'category', label: 'Category', tdClass: 'capitalize text-center' },
            { key: 'source', label: 'Source', tdClass: 'capitalize text-center' },
            { key: 'pricePerEac', label: 'Price per MWh', tdClass: 'text-right' },
            { key: 'totalEacs', label: 'Amount', tdClass: 'text-right' },
            { key: 'pricePenniesUsd', label: 'Price', tdClass: 'text-right' },
          ]"
          row-id-field="id"
          table-class="min-w-main-content mx-auto text-body-2"
          tr-class="cursor-pointer hover:bg-highlight-frostblue transition-colors"
          td-class="px-3 py-2 whitespace-nowrap"
          th-class="text-center"
          default-sort-key="provider"
          :truncate-after="12"
          @row-clicked="handleRowClicked">
          <template #category="{ row }">
            {{ row.category.replaceAll("_", " ") }}
          </template>
          <template #totalEacs="{ row }">
            {{ getFormattedEacQuantity(row.totalEacs || 0, EacMeasurementParameter.Electricity) }}
          </template>
          <template #averageCarbonIntensityKgco2PerMwh="{ row }">
            {{ row.averageCarbonIntensityKgco2PerMwh.toFixed(1) }}
          </template>
          <template #pricePenniesUsd="{ row }">
            {{ (parseInt(row.pricePenniesUsd) / 100).toLocaleString("en-US", { style: "currency", currency: "USD" }) }}
          </template>
          <template #pricePerEac="{ row }">
            {{ (row.pricePerEac * 10000).toLocaleString("en-US", { style: "currency", currency: "USD" }) }} / MWh
          </template>
        </WcTable>
        <InlineLoading v-if="isLoading" />
        <div v-if="hasError" class="mx-auto max-w-main-content">
          <InlineError error-message="There was a problem loading Marketplace listings. Please try again." />
        </div>
        <div v-if="!listingData.length" class="text-body-3 mt-4 flex w-full justify-center bg-highlight-pastelazure pb-12 pt-8 text-center">
          <div>
            <h3 class="text-heading-5 mt-2">Stay tuned</h3>
            <div class="text-body-2 mt-2 max-w-[540px] md:mt-4">
              The marketplace will be temporarily unavailable as we prepare to launch a new and improved way to purchase EACs.
            </div>
          </div>
        </div>
      </div>
    </MaxHeightContainer>
  </section>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue"
import { useRouter } from "vue-router"
// import { useListingService } from "@/services/service-container"
import { formatDateRange, formatHoursOfDay, Listing } from "@common/models/listing"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import WcTable from "@/components/WcTable/WcTable.vue"
import MaxHeightContainer from "@/components/MaxHeightContainer.vue"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@common/models/order"
import InlineError from "@/components/ui/InlineError.vue"
import { getLocalTZName, handleHourColumnSort } from "./ListingsSection.utils"

type ListingRow = Pick<Listing, "averageCarbonIntensityKgco2PerMwh" | "category" | "id" | "pricePenniesUsd" | "provider" | "source" | "totalEacs"> & {
  dateRange: string
  hoursOfDayLocal: string
  pricePerEac: number
}

const router = useRouter()
// const listingService = useListingService()
const isLoading = ref<boolean>(true)
const listings = ref<Listing[]>([])
const hasError = ref<boolean>(false)
const localTZName = getLocalTZName()

onMounted(async () => {
  try {
    // Don't call because it returns the wrong type
    // listings.value = await listingService.getAllListings()
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading listings", error)
  }
  isLoading.value = false
})

const listingData = computed<ListingRow[]>(() => {
  return listings.value.map((listing) => ({
    id: listing.id,
    dateRange: formatDateRange(listing.dateRange),
    hoursOfDayLocal: formatHoursOfDay(listing.hoursOfDayUtc),
    totalEacs: listing.totalEacs,
    source: listing.source,
    category: listing.category,
    provider: listing.provider,
    averageCarbonIntensityKgco2PerMwh: listing.averageCarbonIntensityKgco2PerMwh,
    pricePenniesUsd: listing.pricePenniesUsd,
    pricePerEac: listing.pricePenniesUsd / listing.totalEacs,
  }))
})

const handleRowClicked = (row: ListingRow, event: MouseEvent | KeyboardEvent) => {
  const path = `/openeac-exchange/listings/${row.id}`
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    window.open(path, "_blank")
  } else {
    router.push(path)
  }
}
</script>
