<template>
  <div v-if="transformedTransactions.length > 0 || isLoading">
    <WcTable
      :data="transformedTransactions"
      :columns="[
        { key: 'createdTime', label: 'Date', align: 'left', thClass: 'w-2/12' },
        { key: 'account', label: 'Account', align: 'left', thClass: 'w-2/12' },
        { key: 'kind', label: 'Description', align: 'left', thClass: 'w-4/12' },
        ...(includePriceInfo ? [{ key: 'penniesUsd', label: 'Amount (USD)', align: 'right', thClass: 'w-2/12' } as TableHeader] : []),
        { key: 'eacCount', label: 'EACs', align: 'right', thClass: 'w-2/12' },
      ]"
      row-id-field="id"
      table-class="text-body-3 w-main-content table-fixed"
      td-class="p-3"
      tr-class="border-b border-neutral-20"
      :sortable="false">
      <template #createdTime="{ row }">
        {{ format(new Date(row.createdTime), "LLLL d, y") }}
      </template>
      <template #kind="{ row }">
        <router-link v-if="getLink(row.original)" :to="getLink(row.original)" class="underline">
          {{ formatDescription(row.original) }}
        </router-link>
        <template v-else>
          {{ formatDescription(row.original) }}
        </template>
      </template>
      <template #penniesUsd="{ row }">
        {{ formatCurrency(row.original) }}
      </template>
      <template #eacCount="{ row }">
        <span :class="row.eacCount < 0 ? 'text-error' : ''">
          {{ getFormattedEacQuantity(row.eacCount || 0, EacMeasurementParameter.Electricity, 2, "exceptZero") }}
        </span>
      </template>
    </WcTable>

    <ButtonCTA v-if="props.summary" type="router-link" :to="{ name: 'wc-history' }" class="mt-4" theme="light" :is-filled="false"
      >See All Transactions</ButtonCTA
    >
    <PaginationButtons v-else :page-info="pageInfo" :is-disabled="isLoading" class="mt-4" @load-items="loadTransactions" />
  </div>
  <div v-else>
    <div class="flex flex-col items-center justify-center gap-8 bg-neutral-20 p-8">
      <p class="text-body-2 text-center">There are no entries to show yet</p>
      <div class="flex flex-col items-center justify-between gap-4 md:flex-row">
        <ButtonCTA type="router-link" path="/openeac-exchange" theme="light" :is-filled="true"
          >Browse OpenEAC Exchange <span class="ml-1 hidden md:inline">to buy EACs</span></ButtonCTA
        >
        <ButtonCTA type="router-link" path="/assets/register" theme="light" :is-filled="true"
          >Register an asset <span class="ml-1 hidden md:inline">to generate or track EACs</span></ButtonCTA
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { TableHeader } from "@/components/WcTable/WcTable"
import WcTable from "@/components/WcTable/WcTable.vue"
import { useTransactionService } from "@/services/service-container"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@common/models/order"
import formatCurrencyInPenniesFactory from "@common/utils/formatCurrencyInPenniesFactory"
import { Transaction, Unit } from "@/services/api/transaction.service"
import { format } from "date-fns"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import { computed, onMounted, ref } from "vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import { PageInfo } from "@/services/base-fetcher"
import { useMainStore } from "@/store"

const store = useMainStore()

const props = withDefaults(defineProps<{ includePriceInfo?: boolean; kind?: string[]; summary?: boolean }>(), {
  includePriceInfo: true,
  summary: false,
})

const formatCurrencyInPennies = formatCurrencyInPenniesFactory("exceptZero")

const transactionService = useTransactionService()
const isLoading = ref(true)
const pageInfo = ref<PageInfo>()
const transactions = ref<Transaction[]>([])

onMounted(() => {
  loadTransactions()
})

const loadTransactions = async (url?: string) => {
  try {
    isLoading.value = true

    const result = await transactionService.listTransactions({
      url,
      per_page: props.summary ? 5 : 25,
      ...(props.kind && { kind: props.kind }),
    })
    pageInfo.value = result.pageInfo
    transactions.value = result.data
  } finally {
    isLoading.value = false
  }
}

const formatDescription = (transaction: Transaction) => {
  let details = transaction.details[0] // Just take the first one since that's good enough for now
  switch (details.kind) {
    case "minting":
      return `EACs Created`
    case "allocation":
      if (details.amount > 0) {
        return `Allocated EACs from Asset #${details.kindDetails.deviceId}`
      } else {
        ;``
        return `EACs from your Asset #${details.kindDetails.deviceId} were distributed to buyers`
      }
    case "transfer":
      return "Transferred"
    case "retirement":
      return `Retired`
    case "purchase":
      if (details.amount == 0) {
        return `Pending purchase`
      } else {
        return `Purchased`
      }
    case "listing_earnings":
      // listing_sale will be second if listing_earnings is first, and that's what we want here
      details = transaction.details[1]
      if (details.amount == 0) {
        return `Pending sale`
      } else {
        return `Sold`
      }
    case "listing_sale":
      if (details.amount == 0) {
        return `Pending sale`
      } else {
        return `Sold`
      }
    case "payment_posted":
      return `Payment processed`
    case "withdrawal":
      return `Withdrew funds`
    default:
      // @ts-ignore - Using transaction.kind in case we add a new transaction type in the BE
      // currently ignoring 'fee' transactions
      return transaction.kind
  }
}

const getLink = (transaction: Transaction) => {
  const details = transaction.details[0]
  switch (details.kind) {
    case "purchase":
      return { name: "wc-listing-confirmation", params: { paymentId: details.kindDetails.paymentId } }
    default:
      return ""
  }
}

const formatCurrency = (transaction: Transaction) => {
  // Note: this only handles one currency unit within a transaction
  const currencyTransactionDetails = transaction.details.filter((d) => d.units === Unit.pennies_usd || d.kind === "purchase")[0]
  if (!currencyTransactionDetails) return "—"
  switch (currencyTransactionDetails.kind) {
    case "purchase":
      return formatCurrencyInPennies(currencyTransactionDetails.kindDetails.paymentPenniesUsd)
    case "fee":
    case "listing_earnings":
    case "payment_posted":
    case "withdrawal":
      return formatCurrencyInPennies(currencyTransactionDetails.amount)
    default:
      return "—"
  }
}

const transformedTransactions = computed(() =>
  transactions.value.map((t) => {
    const accountName = store.accounts.find((a) => a.id === t.accountId)?.name
    if (t.details[0].kind === "listing_earnings") {
      // this means t.details[1].kind === "listing_sale"
      return {
        createdTime: t.createdTime,
        account: accountName,
        kind: t.details[1].kind,
        penniesUsd: formatCurrency(t),
        eacCount: t.details[1].amount,
        original: t,
      }
    }
    return {
      createdTime: t.createdTime,
      account: accountName,
      kind: t.details[0].kind,
      penniesUsd: formatCurrency(t),
      eacCount: t.details[0].amount,
      original: t,
    }
  })
)
</script>
