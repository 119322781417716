import { computed } from "vue"

import type { ButtonColor, ButtonSize, ButtonVariant } from "./WcButton"

type ButtonProps = {
  color: ButtonColor
  isDisabled: boolean
  size: ButtonSize
  variant: ButtonVariant
}

type ButtonState = "default" | "hover" | "active"

type ColorScheme = {
  text: string
  bg: string
  border: string
}

type ColorSchemes = {
  [variant in ButtonVariant]: {
    [color in ButtonColor | "disabled"]: {
      [state in ButtonState]: ColorScheme
    }
  }
}

// This can probably be streamlined eventually, but while this is still new, keeping it explicit makes it easy to adjust as needed.
const colorSchemes: ColorSchemes = {
  contained: {
    primary: {
      default: { text: "text-white", bg: "bg-blue-90", border: "border-blue-90" },
      hover: { text: "text-white", bg: "bg-blue-90", border: "border-blue-90" },
      active: { text: "text-white", bg: "bg-blue-99", border: "border-blue-99" },
    },
    secondary: {
      default: { text: "text-white", bg: "bg-sagetone", border: "border-sagetone" },
      hover: { text: "text-white", bg: "bg-sagetone", border: "border-sagetone" },
      active: { text: "text-white", bg: "bg-blue-70", border: "border-neutral-70" },
    },
    "accent-blue": {
      default: { text: "text-black", bg: "bg-highlight", border: "border-highlight" },
      hover: { text: "text-black", bg: "bg-highlight", border: "border-highlight" },
      active: { text: "text-black", bg: "bg-blue-20", border: "border-blue-20" },
    },
    "accent-yellow": {
      default: { text: "text-black", bg: "bg-highlight-yellow", border: "border-highlight-yellow" },
      hover: { text: "text-black", bg: "bg-highlight-yellow-hover", border: "border-highlight-yellow-hover" },
      active: { text: "text-black", bg: "bg-highlight-yellow-hover", border: "border-highlight-yellow-hover" },
    },
    "primary-dark": {
      default: { text: "text-black", bg: "bg-neutral-10", border: "border-neutral-10" },
      hover: { text: "text-black", bg: "bg-neutral-10", border: "border-neutral-10" },
      active: { text: "text-black", bg: "bg-neutral-20", border: "border-neutral-20" },
    },
    disabled: {
      default: { text: "text-white", bg: "bg-neutral-30", border: "border-neutral-30" },
      hover: { text: "text-white", bg: "bg-neutral-30", border: "border-neutral-30" },
      active: { text: "text-white", bg: "bg-neutral-30", border: "border-neutral-30" },
    },
  },
  outlined: {
    primary: {
      default: { text: "text-black", bg: "bg-transparent", border: "border-blue-90" },
      hover: { text: "text-black", bg: "bg-neutral-10", border: "border-blue-90" },
      active: { text: "text-black", bg: "bg-neutral-30", border: "border-blue-90" },
    },
    secondary: {
      default: { text: "text-black", bg: "bg-transparent", border: "border-sagetone" },
      hover: { text: "text-black", bg: "bg-neutral-10", border: "border-sagetone" },
      active: { text: "text-sagetone", bg: "bg-neutral-30", border: "border-sagetone" },
    },
    "accent-blue": {
      default: { text: "text-highlight", bg: "bg-transparent", border: "border-highlight" },
      hover: { text: "text-highlight", bg: "bg-transparent", border: "border-highlight" },
      active: { text: "text-highlight", bg: "bg-transparent", border: "border-highlight" },
    },
    "accent-yellow": {
      default: { text: "text-highlight-yellow", bg: "bg-transparent", border: "border-highlight-yellow" },
      hover: { text: "text-highlight-yellow", bg: "bg-transparent", border: "border-highlight-yellow" },
      active: { text: "text-highlight-yellow", bg: "bg-transparent", border: "border-highlight-yellow" },
    },
    "primary-dark": {
      default: { text: "text-white", bg: "bg-transparent", border: "border-neutral-10" },
      hover: { text: "text-white", bg: "bg-transparent", border: "border-neutral-10" },
      active: { text: "text-white", bg: "bg-transparent", border: "border-neutral-10" },
    },
    disabled: {
      default: { text: "text-neutral-30", bg: "bg-transparent", border: "border-neutral-30" },
      hover: { text: "text-neutral-30", bg: "bg-transparent", border: "border-neutral-30" },
      active: { text: "text-neutral-30", bg: "bg-transparent", border: "border-neutral-30" },
    },
  },
  text: {
    primary: {
      default: { text: "text-blue-90", bg: "bg-transparent", border: "border-transparent" },
      hover: { text: "text-blue-90", bg: "bg-black/5", border: "border-transparent" },
      active: { text: "text-blue-90", bg: "bg-black/20", border: "border-transparent" },
    },
    secondary: {
      default: { text: "text-sagetone", bg: "bg-transparent", border: "border-transparent" },
      hover: { text: "text-sagetone", bg: "bg-black/5", border: "border-transparent" },
      active: { text: "text-sagetone", bg: "bg-black/20", border: "border-transparent" },
    },
    "accent-blue": {
      default: { text: "text-highlight", bg: "bg-transparent", border: "border-transparent" },
      hover: { text: "text-highlight", bg: "bg-black/5", border: "border-transparent" },
      active: { text: "text-highlight", bg: "bg-black/20", border: "border-transparent" },
    },
    "accent-yellow": {
      default: { text: "text-highlight-yellow", bg: "bg-transparent", border: "border-transparent" },
      hover: { text: "text-highlight-yellow", bg: "bg-black/5", border: "border-transparent" },
      active: { text: "text-highlight-yellow", bg: "bg-black/20", border: "border-transparent" },
    },
    "primary-dark": {
      default: { text: "text-white", bg: "bg-transparent", border: "border-transparent" },
      hover: { text: "text-white", bg: "bg-black/5", border: "border-transparent" },
      active: { text: "text-white", bg: "bg-black/20", border: "border-transparent" },
    },
    disabled: {
      default: { text: "text-neutral-30", bg: "bg-transparent", border: "border-transparent" },
      hover: { text: "text-neutral-30", bg: "bg-transparent", border: "border-transparent" },
      active: { text: "text-neutral-30", bg: "bg-transparent", border: "border-transparent" },
    },
  },
}

const classesBySize = {
  "extra-small": "px-[11px] py-[5px] text-button-extra-small",
  small: "px-[13px] py-[7px] text-button-small",
  medium: "px-[15px] py-[9px] text-button-medium",
  large: "px-[19px] py-[11px] text-button-large",
}

const shadowClassesByVariant = {
  contained: "hover-shadow active-shadow",
  outlined: "",
  text: "",
}

const underlineClassesByVariant = {
  contained: "",
  outlined: "",
  text: "underline active:no-underline hover:no-underline",
}

const getColorClassesByState = (props: ButtonProps, state: ButtonState, prefix?: string) => {
  const scheme = colorSchemes[props.variant!]?.[props.isDisabled ? "disabled" : props.color!]?.[state]
  if (!scheme) {
    return ""
  }
  return Object.values(scheme)
    .map((scheme) => (prefix ? `${prefix}:${scheme}` : scheme))
    .join(" ")
}

const useButtonClasses = (props: ButtonProps) => {
  const getColorClasses = () => {
    const baseClasses = getColorClassesByState(props, "default")
    const hoverClasses = !props.isDisabled ? getColorClassesByState(props, "hover", "hover") : ""
    const activeClasses = !props.isDisabled ? getColorClassesByState(props, "active", "active") : ""
    return [baseClasses, hoverClasses, activeClasses].filter(Boolean).join(" ")
  }

  const buttonClasses = computed(() => {
    const baseClasses = "transition-colors border border-1 rounded"
    const colorClasses = getColorClasses()
    const sizeClasses = classesBySize[props.size!]
    const shadowClasses = !props.isDisabled && shadowClassesByVariant[props.variant]
    const underlineClasses = !props.isDisabled && underlineClassesByVariant[props.variant]

    return [baseClasses, colorClasses, shadowClasses, sizeClasses, underlineClasses].filter(Boolean).join(" ")
  })

  return buttonClasses
}

export default useButtonClasses
