<template>
  <WcModal
    :is-open="isOpen"
    :show-close-button="true"
    :header="isSubmitted ? '' : `${story.name} EAC Custom Order`"
    :size="isSubmitted ? 'medium' : 'large'"
    @update:is-open="handleUpdateIsOpen">
    <template #panel>
      <div :id="`Story view for ${props.story.id}: ${props.story.name}`">
        <div v-if="isSubmitted">
          <img src="@/static/img/icons/mini/goals.svg" width="44px" height="44px" class="mb-6 mt-1.5" />
          <h2 class="text-heading-6 mt-6">Thanks for your information!</h2>
          <p class="text-body-3 mt-4">We'll be in touch shortly to complete your transaction.</p>
          <p class="mt-6">
            In the meantime, don't hesitate to reach out with any questions<br />at
            <a class="text-hyperlink underline" href="mailto:info@wattcarbon.com">info@wattcarbon.com</a>
          </p>
        </div>
        <div v-else>
          <p class="text-subheading-1">
            Fill in your contact information and procurement needs below and we'll connect you directly to the supplier to set up a custom deal.
          </p>
          <p class="text-caption mt-1 py-2">*Required field</p>
          <form ref="formRef" class="text-body-1" @submit.prevent="onSubmit">
            <fieldset class="ph-no-capture mt-2 block md:mt-6">
              <label class="text-body-3 mb-2" for="name">Your Name (First, Last)*</label>
              <input
                id="name"
                v-model="name"
                type="text"
                name="name"
                required
                class="text-body-3 block w-full rounded-md border border-blue-90 px-4 py-3"
                @click="captureClickEvent('Name')" />
            </fieldset>
            <fieldset class="ph-no-capture mt-2 block md:mt-6">
              <label class="text-body-3 mb-2" for="company">Company</label>
              <input
                id="company"
                v-model="company"
                type="text"
                name="company"
                class="text-body-3 block w-full rounded-md border border-blue-90 px-4 py-3"
                @click="captureClickEvent('Company')" />
            </fieldset>
            <fieldset class="ph-no-capture mt-2 block md:mt-6">
              <label class="text-body-3 mb-2" for="email">Email*</label>
              <input
                id="email"
                v-model="email"
                type="email"
                name="email"
                required
                class="text-body-3 block w-full rounded-md border border-blue-90 px-4 py-3"
                @click="captureClickEvent('Email')" />
            </fieldset>
            <fieldset class="ph-no-capture mt-2 block md:mt-6">
              <label class="text-body-3 mb-2" for="phone">{{ scheduleACall ? "Phone*" : "Phone" }}</label>
              <input
                id="phone"
                v-model="phone"
                type="text"
                name="phone"
                :required="scheduleACall"
                class="text-body-3 block w-full rounded-md border border-blue-90 px-4 py-3"
                @click="captureClickEvent('Phone')" />
            </fieldset>
            <fieldset class="ph-no-capture mt-2 flex gap-2 md:mt-6">
              <Checkbox v-model="scheduleACall" input-id="scheduleACall" name="scheduleACall" binary @click="captureClickEvent('Schedule a Call')" />
              <label class="text-body-3 mb-2" for="scheduleACall">Schedule a call</label>
            </fieldset>
            <fieldset class="ph-no-capture mt-2 block md:mt-6">
              <label class="text-body-3 mb-2" for="message">Message / Question</label>
              <textarea
                id="message"
                v-model="message"
                name="message"
                class="text-body-3 block h-36 w-full rounded-md border border-blue-90 px-4 py-3"
                @click="captureClickEvent('Message')"></textarea>
            </fieldset>
            <slot name="additionalFields"></slot>
          </form>
        </div>
      </div>
    </template>
    <template #footer>
      <template v-if="isSubmitted">
        <ButtonCTA theme="dark" is-filled button-classes="bg-highlight" @click="handleUpdateIsOpen(false)">Close</ButtonCTA>
      </template>
      <template v-else>
        <ButtonCTA theme="light" @click="handleUpdateIsOpen(false)">Cancel</ButtonCTA>
        <ButtonCTA button-classes="bg-highlight" @click="formRef?.requestSubmit()">Submit</ButtonCTA>
      </template>
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { ref } from "vue"
import posthog from "posthog-js"
import Checkbox from "primevue/checkbox"
import { useToast } from "vue-toastification"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import WcModal from "@/components/WcModal.vue"
import { getEnvironment } from "@/environment"
import { useMarketStore } from "./market.state"

const { API_BASE_URL } = getEnvironment()
const marketStore = useMarketStore()
const toast = useToast()

const props = defineProps<{ formContext: string; story: { id: number; name: string } }>()

const formRef = ref<HTMLFormElement | null>(null)
const isOpen = ref<boolean>(false)
const isSubmitted = ref<boolean>(false)

const name = ref<string>("")
const company = ref<string>("")
const email = ref<string>("")
const phone = ref<string>("")
const scheduleACall = ref<boolean>(false)
const message = ref<string>("")

const onSubmit = async () => {
  let response
  try {
    const messageStr = `${message.value}\n\nMetadata:\nForm context: ${props.formContext}\nSchedule a call: ${scheduleACall.value ? "Yes" : "No"}`
    response = await fetch(`${API_BASE_URL}/contact-us`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email.value,
        name: name.value ?? "",
        company: company.value ?? "",
        message: messageStr,
      }),
    })
    if (!response.ok) {
      throw new Error(await response.text())
    }

    isSubmitted.value = true
    marketStore.customOrderSubmittedStoryIds.push(props.story.id)
    posthog.capture(`Submitted the "Custom Order" form for story ${props.story.name} (${props.story.id})`)
  } catch (error) {
    console.error(`Error when submitting the "Custom Order" form`, error)
    toast.error("Sorry, we're having an issue with this form. Please try again.")
  }
}

const handleUpdateIsOpen = (newValue: boolean) => {
  isOpen.value = newValue
  if (!newValue) {
    resetModal()
  }
}
const resetModal = () => {
  name.value = ""
  company.value = ""
  email.value = ""
  phone.value = ""
  scheduleACall.value = false
  message.value = ""
  isSubmitted.value = false
}

const openModal = () => handleUpdateIsOpen(true)
const closeModal = () => handleUpdateIsOpen(false)
defineExpose({ openModal, closeModal })

const captureClickEvent = (eventName: string) => {
  posthog.capture(`Clicked on the "${eventName}" field - Custom Order Form`)
}
</script>
