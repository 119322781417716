<template>
  <header class="relative z-40 w-full border-b border-neutral-30 bg-white dark:bg-blue-90">
    <nav
      class="wc-page-container flex min-h-16 w-full max-w-[1440px] flex-wrap items-center justify-between gap-x-10 gap-y-2 lg:flex-nowrap"
      :class="{ spoof: store.isSpoof }">
      <!-- Logo (mobile) -->
      <a :href="WWW_BASE_URL" class="ph-no-capture order-1 flex shrink-0 cursor-pointer justify-start lg:mt-0 lg:hidden" @click="captureHomeEvent">
        <img src="@/static/img/wattcarbon_logo_on-light.svg" alt="WattCarbon" class="h-[30px] pr-2 dark:hidden" />
      </a>
      <div
        class="text-body-2 order-3 basis-full lg:order-2 lg:basis-auto"
        :class="{ 'hidden lg:block': route.meta.appPortal == AppPortal.Marketplace || route.meta.appPortal == AppPortal.Enterprise }">
        <ul class="flex items-center justify-center gap-5 lg:gap-12">
          <!-- Logo (Desktop) -->
          <a :href="WWW_BASE_URL" class="ph-no-capture hidden shrink-0 cursor-pointer justify-start lg:mt-0 lg:flex" @click="captureHomeEvent">
            <img src="@/static/img/wattcarbon_logo_on-light.svg" alt="WattCarbon" class="h-[30px] pr-2 dark:hidden" />
          </a>

          <!-- Marketplace -->
          <li v-if="route.meta.appPortal == AppPortal.Marketplace">
            <a :href="WWW_BASE_URL" class="ph-no-capture py-3" @click="captureMarketplaceEvent">Marketplace</a>
          </li>
          <li
            v-if="route.meta.appPortal == AppPortal.Marketplace"
            class="hover:text-black"
            @mouseover="showHeaderDropdowns(HeaderDropdowns.Resources)"
            @mouseleave="hideHeaderDropdowns">
            <div class="text-body-2 flex cursor-pointer items-center gap-2">
              Resources
              <ChevronIcon class="transition-transform" :class="currentHeaderDropdown === HeaderDropdowns.Resources ? 'rotate-180' : 'rotate-0'" />
            </div>
            <transition name="fade">
              <div
                v-if="currentHeaderDropdown === HeaderDropdowns.Resources"
                class="absolute -mx-4 flex w-36 flex-col items-start gap-4 bg-white px-4 py-2">
                <ButtonNav
                  theme="light"
                  type="anchor"
                  :href="`${WWW_BASE_URL}/weats`"
                  class="ph-no-capture"
                  button-classes="text-body-2"
                  @click="captureEvent"
                  >Registry</ButtonNav
                >
                <ButtonNav
                  theme="light"
                  type="anchor"
                  :href="`${WWW_BASE_URL}/faq`"
                  class="ph-no-capture"
                  button-classes="text-body-2"
                  @click="captureEvent"
                  >FAQ</ButtonNav
                >
                <ButtonNav
                  theme="light"
                  type="anchor"
                  href="https://blog.wattcarbon.com"
                  class="ph-no-capture"
                  button-classes="text-body-2"
                  open-in-new-tab="true"
                  @click="captureEvent"
                  >Blog</ButtonNav
                >
              </div>
            </transition>
          </li>

          <!-- Enterprise -->
          <li
            v-if="route.meta.appPortal == AppPortal.Enterprise"
            class="hover:text-black"
            @mouseover="showHeaderDropdowns(HeaderDropdowns.Enterprise)"
            @mouseleave="hideHeaderDropdowns">
            <div
              class="text-subheading-2 flex cursor-pointer items-center gap-2"
              :class="{ 'text-hint': currentHeaderDropdown === HeaderDropdowns.Enterprise }">
              Enterprise
            </div>
            <transition name="fade">
              <div v-if="currentHeaderDropdown === HeaderDropdowns.Enterprise" class="nav-menu">
                <div class="nav-menu-inner">
                  <a :href="`${WWW_BASE_URL}/products/weats-pro`" class="ph-no-capture text-body-2" @click="captureEvent">WEATS Pro</a>
                  <a :href="`${WWW_BASE_URL}/products/procurement`" class="ph-no-capture text-body-2" @click="captureEvent">Procurement</a>
                </div>
              </div>
            </transition>
          </li>
          <li
            v-if="route.meta.appPortal == AppPortal.Enterprise"
            :class="getMenuClass(['wc-marketplace', 'wc-openeac-exchange', 'wc-listing', 'wc-story'])"
            class="text-subheading-2">
            <a :href="`${WWW_BASE_URL}/openeac-exchange`" class="ph-no-capture py-3" @click="captureMarketplaceEvent">OpenEAC Exchange</a>
          </li>
          <li v-if="route.meta.appPortal == AppPortal.Enterprise" class="text-subheading-2">
            <a :href="`${WWW_BASE_URL}/weats`" class="ph-no-capture py-3" @click="captureMarketplaceEvent">WEATS Registry</a>
          </li>
          <li
            v-if="route.meta.appPortal == AppPortal.Enterprise"
            class="hover:text-black"
            @mouseover="showHeaderDropdowns(HeaderDropdowns.Resources)"
            @mouseleave="hideHeaderDropdowns">
            <div
              class="text-subheading-2 flex cursor-pointer items-center gap-2"
              :class="{ 'text-hint': currentHeaderDropdown === HeaderDropdowns.Resources }">
              Resources
            </div>
            <transition name="fade">
              <div v-if="currentHeaderDropdown === HeaderDropdowns.Resources" class="nav-menu">
                <div class="nav-menu-inner">
                  <a :href="`${WWW_BASE_URL}/faq`" class="ph-no-capture text-body-2" @click="captureEvent">FAQ</a>
                  <a href="https://blog.wattcarbon.com" class="ph-no-capture text-body-2" target="_blank" @click="captureEvent">Blog</a>
                </div>
              </div>
            </transition>
          </li>
          <li
            v-if="route.meta.appPortal == AppPortal.Enterprise"
            :class="getMenuClass(['wc-enterprise', 'wc-eacs', 'wc-accounting', 'wc-load'])"
            class="text-subheading-2">
            <router-link to="/dashboard" class="ph-no-capture py-3" @click="captureEvent">Dashboard</router-link>
          </li>
        </ul>
      </div>
      <div class="order-2 flex py-4 lg:order-3">
        <ul class="mr-10 hidden items-center gap-5 lg:flex">
          <li v-if="route.meta.appPortal == AppPortal.Marketplace">
            <router-link to="/assets" class="text-body-2 ph-no-capture py-3" aria-current="page" @click="captureEvent">Sell EACs</router-link>
          </li>
          <li v-if="route.meta.appPortal == AppPortal.Accounts">
            <a :href="WWW_BASE_URL" class="text-body-2 ph-no-capture py-3" @click="captureMarketplaceEvent">Marketplace</a>
          </li>
        </ul>
        <Menu as="div" class="text-body-2 relative">
          <MenuButton class="flex items-center gap-3">
            <div v-if="!store.isLoggedIn" class="size-[30px] rounded-full bg-highlight-yellow"></div>
            <div
              v-else
              class="text-body-2 flex size-8 items-center justify-center rounded-full bg-highlight-yellow text-center"
              data-cy="header-menu-icon">
              {{ store.account.name?.[0] }}
            </div>
          </MenuButton>
          <transition>
            <MenuItems
              v-if="store.isLoggedIn"
              class="absolute right-0 mt-2 w-[240px] origin-top-right divide-y divide-neutral-20 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none dark:bg-blue-90 dark:text-white">
              <div class="px-3 py-4">
                <section class="pb-1">
                  <div class="group flex w-full cursor-pointer flex-col rounded-sm border-b border-neutral-20 px-2 pb-2 pt-1">
                    <div class="text-body-2 mb-2 text-sagetone">You are logged in as</div>
                    <div class="text-body-1">{{ store.account.name }}</div>
                  </div>
                  <MenuItem as="div" class="mt-1 cursor-pointer" @click="store.isAccountSwitcherModalOpen = true">
                    <div class="group flex w-full items-center rounded-sm px-2 py-1 hover:bg-neutral-20" data-cy="header-menu-switch-account">
                      <Icon icon="wc-carbon:user-multiple" />
                      <div class="ml-2">Switch account</div>
                    </div>
                  </MenuItem>
                  <MenuItem as="div" class="mt-1 cursor-pointer" @click="captureEvent">
                    <router-link
                      to="/accounts/manage"
                      class="group flex w-full items-center rounded-sm px-2 py-1 hover:bg-neutral-20"
                      active-class="bg-highlight-frostblue">
                      <Icon icon="wc-carbon:credentials" />
                      <div class="ml-2">Manage accounts</div>
                    </router-link>
                  </MenuItem>
                </section>
                <section class="border-t border-neutral-30 pb-1 lg:hidden">
                  <MenuItem as="div" class="mt-1 cursor-pointer hover:bg-neutral-20" :class="getMenuClass(['wc-enterprise'])" @click="captureEvent">
                    <router-link to="/dashboard" class="group flex w-full items-center rounded-sm px-2 py-1">
                      <div>Dashboard</div>
                    </router-link>
                  </MenuItem>
                  <MenuItem as="div" class="mt-1 cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <a
                      :href="`${WWW_BASE_URL}/openeac-exchange`"
                      class="ph-no-capture group flex w-full items-center rounded-sm px-2 py-1"
                      @click="captureMarketplaceEvent"
                      >OpenEAC Exchange</a
                    >
                  </MenuItem>
                  <MenuItem as="div" class="mt-1 cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <a
                      :href="`${WWW_BASE_URL}/weats`"
                      class="ph-no-capture group flex w-full items-center rounded-sm px-2 py-1"
                      @click="captureMarketplaceEvent"
                      >WEATS Registry</a
                    >
                  </MenuItem>
                  <div class="text-body-3 mb-1 ml-2 mt-2 text-sagetone">Enterprise</div>
                  <MenuItem as="div" class="mt-1 cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <a
                      :href="`${WWW_BASE_URL}/products/weats-pro`"
                      class="ph-no-capture text-body-2 group flex w-full items-center rounded-sm px-2 py-1"
                      @click="captureEvent">
                      <div class="ml-2">WEATS Pro</div>
                    </a>
                  </MenuItem>
                  <MenuItem as="div" class="mt-1 cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <a
                      :href="`${WWW_BASE_URL}/products/procurement`"
                      class="ph-no-capture text-body-2 group flex w-full items-center rounded-sm px-2 py-1"
                      @click="captureEvent">
                      <div class="ml-2">Procurement</div>
                    </a>
                  </MenuItem>
                  <div class="text-body-3 mb-1 ml-2 mt-2 text-sagetone">Resources</div>
                  <MenuItem as="div" class="mt-1 cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <a :href="`${WWW_BASE_URL}/faq`" class="ph-no-capture group flex w-full items-center rounded-sm px-2 py-1">
                      <div class="ml-2">FAQ</div>
                    </a>
                  </MenuItem>
                  <MenuItem as="div" class="mt-1 cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <a href="https://blog.wattcarbon.com" class="ph-no-capture group flex w-full items-center rounded-sm px-2 py-1">
                      <div class="ml-2">Blog</div>
                    </a>
                  </MenuItem>
                </section>
                <section :class="{ 'border-t border-neutral-30 pb-1': store.isAdmin }">
                  <MenuItem
                    v-if="store.isAdmin"
                    as="div"
                    class="mt-1 cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.AdminPortal ? 'bg-highlight-frostblue' : 'hover:bg-neutral-20'"
                    @click="captureEvent">
                    <router-link to="/admin" class="group flex w-full items-center rounded-sm px-2 py-1">
                      <div>Admin Portal</div>
                    </router-link>
                  </MenuItem>
                  <MenuItem
                    as="div"
                    class="mt-1 cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.AdminPortal ? 'bg-highlight-frostblue' : 'hover:bg-neutral-20'"
                    @click="captureEvent">
                    <a :href="`${WWW_BASE_URL}/terms`" class="group flex w-full items-center rounded-sm px-2 py-1">
                      <div>Terms & Conditions</div>
                    </a>
                  </MenuItem>
                </section>
                <section class="border-t border-neutral-30 pb-1">
                  <MenuItem
                    v-if="store.isSpoof"
                    as="div"
                    class="mt-1 cursor-pointer rounded-sm bg-neutral-30 p-2 text-center hover:bg-neutral-20"
                    @click="unspoof">
                    <div>Unspoof</div>
                  </MenuItem>
                  <MenuItem as="div" class="mt-1 cursor-pointer rounded-sm hover:bg-neutral-20" @click="logout">
                    <div class="group flex w-full items-center px-2 py-1">
                      <Icon icon="wc-carbon:logout" />
                      <div class="ml-2">Sign Out</div>
                    </div>
                  </MenuItem>
                </section>
              </div>
            </MenuItems>
            <MenuItems
              v-else
              class="text-body-1 absolute right-0 mt-2 w-[240px] origin-top-right divide-y divide-neutral-20 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <div class="py-1">
                <section class="border-b border-neutral-20 py-2">
                  <MenuItem as="div" class="cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <router-link to="/register" class="text-body-1-strong group flex w-full items-center rounded-sm p-2 hover:bg-neutral-20">
                      <div class="ml-2">Create an account</div>
                    </router-link>
                  </MenuItem>
                  <MenuItem as="div" class="cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <router-link to="/register" class="group flex w-full items-center rounded-sm p-2 hover:bg-neutral-20">
                      <div class="ml-2">Sign in</div>
                    </router-link>
                  </MenuItem>
                </section>
                <section class="border-b border-neutral-20 py-2">
                  <MenuItem as="div" class="cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <router-link
                      to="/openeac-exchange"
                      class="group flex w-full items-center rounded-sm p-2 hover:bg-neutral-20"
                      @click="captureEvent">
                      <span class="ml-2">Marketplace</span>
                    </router-link>
                  </MenuItem>
                  <MenuItem as="div" class="cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <router-link to="/assets" class="group flex w-full items-center rounded-sm p-2 hover:bg-neutral-20">
                      <div class="ml-2">Sell EACs</div>
                    </router-link>
                  </MenuItem>
                </section>
                <section class="py-2">
                  <div class="text-body-3 my-1 ml-4 text-sagetone">Resources</div>
                  <MenuItem
                    as="div"
                    class="cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.AdminPortal ? 'bg-highlight-frostblue' : 'hover:bg-neutral-20'"
                    @click="captureEvent">
                    <a :href="`${WWW_BASE_URL}/weats`" class="group flex w-full items-center rounded-sm p-2">
                      <div class="ml-2">Registry</div>
                    </a>
                  </MenuItem>
                  <MenuItem
                    as="div"
                    class="cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.AdminPortal ? 'bg-highlight-frostblue' : 'hover:bg-neutral-20'"
                    @click="captureEvent">
                    <a :href="`${WWW_BASE_URL}/faq`" class="group flex w-full items-center rounded-sm p-2">
                      <div class="ml-2">FAQ</div>
                    </a>
                  </MenuItem>
                  <MenuItem
                    as="div"
                    class="cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.AdminPortal ? 'bg-highlight-frostblue' : 'hover:bg-neutral-20'"
                    @click="captureEvent">
                    <a href="https://blog.wattcarbon.com" class="group flex w-full items-center rounded-sm p-2" target="_blank">
                      <div class="ml-2">Blog</div>
                    </a>
                  </MenuItem>
                </section>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </nav>
  </header>
  <AccountSwitcher />
</template>

<script lang="ts" setup>
import { getEnvironment } from "@/environment"
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue"
import { useRoute, useRouter } from "vue-router"
import { Icon } from "@iconify/vue"
import { onMounted, ref } from "vue"
import posthog from "posthog-js"
import { useMainStore } from "@/store"
import { AppPortal } from "@common/models/models"
import { useAccountsService, useAuthService } from "@/services/service-container"
import ChevronIcon from "@/components/icon/ChevronIcon.vue"
import AccountSwitcher from "@/modules/accounts/AccountSwitcher.vue"
import ButtonNav from "../ui/ButtonNav.vue"

const { WWW_BASE_URL } = getEnvironment()
const route = useRoute()

const { push } = useRouter()
const store = useMainStore()
const authService = useAuthService()
const accountsService = useAccountsService()

const captureHomeEvent = () => posthog.capture("Clicked on the 'Home' link - Nav Header")
const captureMarketplaceEvent = () => posthog.capture("Clicked on the 'Marketplace' link - Nav Header")
const captureSignOutEvent = () => posthog.capture("Clicked on the 'Sign Out' link - Nav Header")
const captureEvent = (e: PointerEvent | MouseEvent) => posthog.capture(`Clicked on the '${(e.target as HTMLElement).textContent}' link - Nav Header`)

const logout = async () => {
  captureSignOutEvent()
  authService.logout()
  await push({ name: "wc-landing" })
}

const unspoof = async () => {
  await authService.unspoof()
  store.accounts = await accountsService.listMyAccounts()
  await push({ path: "/" })
}

onMounted(async () => {
  if (store.isLoggedIn) {
    store.accounts = await accountsService.listMyAccounts()
  }
})

const getMenuClass = (prefixes: Array<string>) => {
  return prefixes.find((prefix) => route.name?.toString().indexOf(prefix) === 0) ? "active" : ""
}

enum HeaderDropdowns {
  Enterprise = "Enterprise",
  Resources = "Resources",
}
const currentHeaderDropdown = ref<HeaderDropdowns>()
const showHeaderDropdowns = (dropdown: HeaderDropdowns) => {
  currentHeaderDropdown.value = dropdown
}
const hideHeaderDropdowns = () => {
  currentHeaderDropdown.value = undefined
}
</script>

<style scoped lang="scss">
@tailwind utilities;

ul {
  li {
    @apply dark:text-white;

    &:hover {
      @apply text-hint;
    }

    &.active {
      @apply font-bold #{!important};
      @apply lg:-mb-[22px] lg:pb-[19px] lg:border-b-[3px] lg:border-b-sagetone lg:-mx-6 lg:px-6;
    }
  }
}
nav.spoof {
  background-color: theme("colors.blue.70");

  div ul.tabs {
    li {
      @apply border-b-4 border-b-blue-40;

      &:hover,
      &.active {
        @apply border-b-neutral-99;
      }

      &.active {
        @apply shadow-md;
      }
    }
  }
}
.nav-menu {
  @apply absolute -ml-2 w-[153px];

  .nav-menu-inner {
    @apply mt-3 flex flex-col items-start rounded bg-white py-1;
    box-shadow: 0px 8px 8px 0px rgba(4, 15, 36, 0.04), 0px 5px 14px 0px rgba(4, 15, 36, 0.06);

    a {
      @apply w-full px-3 py-2 hover:bg-neutral-20;
    }
  }
}
div[role="menuitem"].active {
  @apply bg-highlight-frostblue;
}
</style>
