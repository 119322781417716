<template>
  <section class="flex flex-col">
    <AppPageSectionHeader class="mb-6 border-b border-neutral-30 pb-3">Select EACs by Date and Hour (UTC)</AppPageSectionHeader>
    <div class="mb-2 flex items-baseline justify-between">
      <div class="flex grow flex-wrap items-center gap-3">
        <SelectSuppliers v-model="filterSuppliers" class="shrink-0 basis-40" name="suppliers" size="small" />
        <WcCalendarRange
          v-model="filterDateRange"
          class="shrink-0 basis-40"
          :class="{ 'basis-56': (filterDateRange || [])[1] != null }"
          name="filterDateRange"
          inset-label="Date Range"
          size="small">
          <template #presetPanel>
            <div class="text-subheading-1 mb-3">Quarters</div>
            <div class="flex flex-wrap gap-4">
              <WcCTA
                v-for="quarter in quarters"
                :key="quarter.description"
                :text="quarter.description"
                variant="text"
                size="large"
                :weight="filterDateRange && quarter.dateRange.startDate === startDate && quarter.dateRange.endDate === endDate ? 'heavy' : 'normal'"
                @click="handleUpdateDateRange(quarter?.dateRange)" />
            </div>
          </template>
        </WcCalendarRange>
        <div class="mx-1 min-h-12 border-l border-divider-light max-md:invisible" />
        <div class="flex gap-4">
          <div class="flex">
            <input id="radio-one" v-model="filterScope" class="cursor-pointer" type="radio" name="switch-one" :value="1" />
            <label class="cursor-pointer pl-2" for="radio-one">Scope 1</label>
          </div>
          <div class="flex">
            <input id="radio-two" v-model="filterScope" class="cursor-pointer" type="radio" name="switch-one" :value="2" />
            <label class="cursor-pointer pl-2" for="radio-two">Scope 2</label>
          </div>
          <div class="flex">
            <input id="radio-three" v-model="filterScope" class="cursor-pointer" type="radio" name="switch-one" :value="3" />
            <label class="cursor-pointer pl-2" for="radio-three">Scope 3</label>
          </div>
        </div>
      </div>
      <WcCTA
        class="shrink-0 pl-1"
        icon-position="right"
        icon="wc-carbon:close-outline"
        text="Clear Filters"
        size="small"
        @click="handleClearFilters" />
    </div>
    <EacHourGrid
      v-if="filterScope === 2"
      ref="eacGrid"
      :balances="eacBalances"
      :is-loading="isLoadingBalanceSummary"
      :start-date="startDate ?? DEFAULT_START_DATE"
      :end-date="endDate ?? DEFAULT_END_DATE"
      @eac-range-selected="debouncedHandleEacRangeSelected" />
    <!-- TODO: Actually load data for Scopes 1 and 3 to show here -->
    <EacMonthGrid
      v-else
      ref="eacGrid"
      :balances="[]"
      :is-loading="isLoadingBalanceSummary"
      :start-date="startDate ?? DEFAULT_START_DATE"
      :end-date="endDate ?? DEFAULT_END_DATE"
      @eac-range-selected="debouncedHandleEacRangeSelected" />
    <EacGridLegend />
  </section>

  <!-- Selected EACs-->
  <section class="relative !mb-32 min-h-[20rem]">
    <AppPageSectionHeader
      class="sticky top-0 z-10 flex w-full items-end justify-between bg-white py-4"
      :class="selectedEacs.length ? '!-mb-12' : '!mb-0'">
      Selected EACs
    </AppPageSectionHeader>
    <!-- The negative bottom margin for this element compensates for the empty space leftover by the sticky element's original placement -->
    <div
      v-if="selectedEacs.length > 0"
      class="sticky top-[calc(100%-64px)] z-10 -mb-16 mt-8 flex h-16 w-full flex-wrap items-center justify-between gap-2 border-b border-neutral-30 bg-white">
      <div class="text-body-2 shrink-0">
        <div class="flex items-baseline gap-2">
          <span class="text-subheading-large-bold">{{ totalSelectedQuantity }}</span>
          <span class="text-subheading-1">{{ totalSelectedQuantityUnit }} selected</span>
        </div>
      </div>
      <div class="flex items-center gap-2">
        <WcButton text="Clear Selection" size="small" variant="outlined" :is-disabled="eacRangeSelection === null" @click="handleResetSelection" />
        <WcButton text="Retire EACs" size="small" :is-disabled="!canTransact" @click="openRetireEacsModal" />
        <WcButton text="Transfer EACs" size="small" :is-disabled="!canTransact" @click="openTransferEacsModal" />
        <WcButton
          text="Download CSV"
          size="small"
          icon="wc-carbon:download"
          icon-position="right"
          :is-disabled="selectedEacs.length === 0"
          @click="handleDownloadCSV" />
      </div>
    </div>
    <div v-if="isLeadingEacs" class="flex h-36 items-center justify-center">
      <WcLoadingSpinner />
    </div>
    <div v-else-if="selectedEacs.length" class="relative overflow-x-scroll">
      <WcTable
        :data="selectedEacs"
        :columns="EAC_TABLE_HEADERS"
        row-id-field="EAC Start ID"
        table-class="mt-4 text-body-3 min-w-main-content table-fixed"
        tr-class="border-b border-neutral-20"
        td-class="p-3">
        <template #status="{ row }">
          <span :class="{ 'text-error': row.status === 'Retired' }">
            {{ row.status }}
          </span>
        </template>
        <template #emissionsIntensity="{ row }">
          <span v-if="row.emissionsIntensity" class="group/emissionsIntensity relative">
            <span class="invisible group-hover/emissionsIntensity:visible"> {{ row.emissionsIntensity }} </span>
            <span class="absolute left-0 group-hover/emissionsIntensity:hidden"> {{ row.emissionsIntensity.slice(0, 7) }} ... </span>
          </span>
        </template>
        <template #totalAvoidedEmissions="{ row }">
          <span v-if="row.totalAvoidedEmissions" class="group/totalAvoidedEmissions relative">
            <span class="invisible group-hover/totalAvoidedEmissions:visible"> {{ row.totalAvoidedEmissions }} </span>
            <span class="absolute left-0 group-hover/totalAvoidedEmissions:hidden"> {{ row.totalAvoidedEmissions.slice(0, 5) }} ... </span>
          </span>
        </template>
      </WcTable>
      <PaginationButtons class="mb-12" :page-info="pageInfo" @load-items="(url?: string) => loadEacs({ url })" />
    </div>
    <section v-else class="text-subheading-1 mx-auto mt-16 w-2/3 bg-neutral-10 py-16 text-center">You have no EACs selected</section>
  </section>

  <EnterpriseRetireEacsModal
    ref="retireEacsModal"
    :selected-eacs="selectedEacs"
    :total-selected-active-quantity="totalSelectedActiveQuantity"
    @eacs-retired="handleEacsRetired" />

  <ContactUsForm
    ref="transferEacsModal"
    client:load
    form-context="WEATS Pro Manage EACs"
    :form-context-metadata="{
      'Date Range': transferModalDateTimeContext,
      'Supplier IDs': filterSuppliers ? filterSuppliers.toString() : 'All',
    }"
    title="Transfer EACs"
    description="Fill in your contact information and transfer needs and we'll reach out to conduct the transfer."
    success-message="We'll reach out shortly to facilitate the transfer." />
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import type { AssetKind } from "@common/models/asset"
import { ASSET_KIND } from "@common/models/asset"
import { WcButton, WcCTA } from "@/components/button"
import ContactUsForm from "@/components/ContactUsForm.vue"
import { WcCalendarRange } from "@/components/input"
import { AppPageSectionHeader } from "@/components/layout"
import EacMonthGrid from "./components/EacMonthGrid.vue"
import EacHourGrid from "./components/EacHourGrid.vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import SelectSuppliers from "@/modules/accounts/components/SelectSuppliers.vue"
import type { PageInfo } from "@/services/base-fetcher"
import { useRegistryService } from "@/services/service-container"
import type { EacBalances, NullableEacRangeSelection } from "@/modules/accounts/eac.state"
import type { TableHeader } from "@/components/WcTable/WcTable"
import WcTable from "@/components/WcTable/WcTable.vue"
import WcLoadingSpinner from "@/components/WcLoadingSpinner.vue"
import { addDays } from "date-fns"
import { debounce } from "@/utils/debounce"
import { balancesByHour } from "@/modules/accounts/eac.state"
import { activeQuantityAcrossBalances, filterToSelectedBalances, parseEacCsvData, quantityAcrossBalances } from "./EnterpriseManageEACs.utils"
import EnterpriseRetireEacsModal from "./EnterpriseRetireEacsModal.vue"
import EacGridLegend from "./components/EacGridLegend.vue"
import type { Quarter } from "@/modules/accounts/components/QuarterSelector.utils"
import { constructAvailableEacQuarters } from "@/modules/accounts/components/QuarterSelector.utils"

const DEFAULT_START_DATE = new Date("2024-04-01T00:00:00")
const DEFAULT_END_DATE = new Date("2024-06-31T00:00:00")

const SINGLE_COLUMN = "min-w-[108px]"
const DOUBLE_COLUMN = "min-w-[216px]"

const EAC_TABLE_HEADERS: TableHeader[] = [
  { key: "status", label: "Status", align: "left", thClass: SINGLE_COLUMN },
  { key: "generationSavingsDateTime", label: "Generation / Savings Time", align: "left", thClass: DOUBLE_COLUMN },
  { key: "eacQuantityVolume", label: "EAC Quantity / Volume", align: "left", thClass: SINGLE_COLUMN },
  { key: "eacStartId", label: "EAC Start ID", align: "left", thClass: SINGLE_COLUMN },
  { key: "eacEndId", label: "EAC End ID", align: "left", thClass: SINGLE_COLUMN },
  { key: "portfolioName", label: "Portfolio Name", align: "left", thClass: `${SINGLE_COLUMN} text-nowrap` },
  { key: "supplierName", label: "Supplier Name", align: "left", thClass: DOUBLE_COLUMN },
  { key: "projectCategory", label: "Project Category", align: "left", thClass: DOUBLE_COLUMN },
  { key: "balancingAuthority", label: "Balancing Authority", align: "left", thClass: SINGLE_COLUMN },
  { key: "stateLocation", label: "State / Location", align: "left", thClass: DOUBLE_COLUMN },
  { key: "emissionsIntensity", label: "Emissions Intensity (kgCO2e/MWh)", align: "left", thClass: `${DOUBLE_COLUMN} text-nowrap` },
  { key: "totalAvoidedEmissions", label: "Total Avoided Emissions (kgCO2e)", align: "left", thClass: `${DOUBLE_COLUMN} text-nowrap` },
]

const registryService = useRegistryService()

const filterDateRange = ref<Date[] | null | undefined>(null)
const filterSuppliers = ref<number[] | null>(null)
const filterScope = ref<number>(2)

const isLoadingBalanceSummary = ref<boolean>(false)
const isLeadingEacs = ref<boolean>(false)
const eacBalances = ref<EacBalances | null>(null)
const selectedEacs = ref<any[]>([])
const pageInfo = ref<PageInfo>()
const rawCsvData = ref<string>("")
const eacRangeSelection = ref<NullableEacRangeSelection>(null)
const transferModalDateTimeContext = ref<string>("")
const retireEacsModal = ref()
const transferEacsModal = ref()
const eacGrid = ref()
const quarters = ref<Quarter[]>([])

const selectedBalances = computed(() => filterToSelectedBalances(eacBalances.value ?? {}, eacRangeSelection.value))
const totalSelectedQuantity = computed(() => {
  return quantityAcrossBalances(selectedBalances.value).toLocaleString()
})
const totalSelectedQuantityUnit = computed(() => {
  return filterScope.value === 2 ? "Wh" : "gCO2e"
})
const totalSelectedActiveQuantity = computed(() => activeQuantityAcrossBalances(selectedBalances.value))

const startDate = computed(() => filterDateRange.value?.[0] ?? null)
const endDate = computed(() => filterDateRange.value?.[1] ?? null)

const canTransact = computed(() => selectedEacs.value.length > 0 && totalSelectedActiveQuantity.value > 0)

const loadEacBalanceSummary = async () => {
  if (startDate.value == null || endDate.value == null) {
    return
  }
  isLoadingBalanceSummary.value = true
  try {
    // Fetch EAC balances
    const hourlySummary = await registryService.getHourlyBalanceSummary({
      startDate: startDate.value,
      endDate: endDate.value,
      supplierIds: filterSuppliers.value,
    })
    eacBalances.value = balancesByHour(hourlySummary, startDate.value)
  } catch (error) {
    console.error("Error fetching EAC balances", error)
  } finally {
    isLoadingBalanceSummary.value = false
  }
}

const loadAvailableEacQuarters = async () => {
  const dateRange = await registryService.getBalanceDateRange()
  const availableQuarters = constructAvailableEacQuarters(dateRange)

  quarters.value = availableQuarters

  const defaultDateRange = availableQuarters[0].dateRange
  filterDateRange.value = [defaultDateRange.startDate, defaultDateRange.endDate]
}

watch([filterDateRange, filterSuppliers, filterScope], () => {
  handleResetSelection()
  loadEacBalanceSummary()
})

const loadEacsFromSelection = async (selection: NullableEacRangeSelection) => {
  if (selection == null || startDate.value == null) {
    return
  }
  const dateTimeRange = {
    startDate: addDays(startDate.value, selection.minDateIndex),
    endDate: addDays(startDate.value, selection.maxDateIndex + 1),
    startHour: selection.minHourIndex,
    endHour: selection.maxHourIndex + 1,
  }
  transferModalDateTimeContext.value = JSON.stringify(dateTimeRange)
  await loadEacs({ dateTimeRange, supplierIds: filterSuppliers.value })
}

const loadEacs = async (options: { dateTimeRange?: any; supplierIds?: any; url?: any }) => {
  isLeadingEacs.value = true
  selectedEacs.value = []
  pageInfo.value = undefined

  const result = await registryService.getBalances(options)

  rawCsvData.value = result.data
  pageInfo.value = result.pageInfo

  const { records } = parseEacCsvData(rawCsvData.value)

  const formattedRecords = records.map((record) => {
    let location
    try {
      location = JSON.parse(record["Location"])
    } catch {
      location = {}
    }
    const coordinates = location.latitude && location.longitude ? `${location.latitude}, ${location.longitude}` : ""

    const generationSavingsDate = new Date(record["Generation/Savings Time"])
    const status = record["Status"] === "active" ? "Active" : record["Status"] === "retired" ? "Retired" : record["Status"]
    return {
      status,
      generationSavingsDateTime: `${generationSavingsDate.toISOString().split("T")[0]} ${
        generationSavingsDate.toISOString().split("T")[1].split(".")[0]
      }`,
      eacQuantityVolume: `${record["EAC Quantity"]} ${record["Units"]}`,
      eacStartId: `${record["EAC Start ID"].slice(0, 5)}...${record["EAC Start ID"].slice(-4)}`,
      eacCompleteStartId: record["EAC Start ID"],
      eacEndId: `${record["EAC End ID"].slice(0, 5)}...${record["EAC End ID"].slice(-4)}`,
      portfolioName: record["Portfolio Name"],
      supplierName: record["Supplier Name"],
      projectCategory: ASSET_KIND[record["Project Category"] as AssetKind] ?? record["Project Category"],
      balancingAuthority: record["Balancing Authority"],
      stateLocation: `${record["State"]} / ${coordinates}`,
      emissionsIntensity: record["Emissions Intensity (kgCO2/MWh)"],
      totalAvoidedEmissions: record["Total Avoided Emissions (kgCO2)"],
    }
  })

  selectedEacs.value = formattedRecords
  isLeadingEacs.value = false
}

onMounted(async () => {
  loadEacBalanceSummary()
  loadAvailableEacQuarters()
})

const handleClearFilters = () => {
  filterDateRange.value = null
  filterSuppliers.value = null
}

const handleUpdateDateRange = (dateRange?: Quarter["dateRange"]) => {
  if (!dateRange) {
    filterDateRange.value = null
  } else {
    filterDateRange.value = [dateRange.startDate, dateRange.endDate]
  }
}

const handleEacRangeSelected = async (selection: NullableEacRangeSelection) => {
  eacRangeSelection.value = selection
  if (selection) {
    await loadEacsFromSelection(selection)
  } else {
    selectedEacs.value = []
    transferModalDateTimeContext.value = ""
  }
}

const debouncedHandleEacRangeSelected = debounce(handleEacRangeSelected, 500)

const openRetireEacsModal = () => {
  retireEacsModal.value.openModal()
}
const openTransferEacsModal = () => {
  transferEacsModal.value.openContactForm()
}
const handleEacsRetired = async () => {
  for (const eac of selectedEacs.value) {
    eac.status = "Retired"
  }
  await loadEacBalanceSummary()
}
const handleDownloadCSV = () => {
  if (!selectedEacs.value.length) return
  const csvContent = "data:text/csv;charset=utf-8," + rawCsvData.value
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement("a")
  link.setAttribute("href", encodedUri)
  link.setAttribute("download", "eacs.csv")
  document.body.appendChild(link)
  link.click()
}
const handleResetSelection = () => {
  eacGrid.value.resetSelection()
}
</script>
