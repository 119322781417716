<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" :class="props.iconClasses">
    <circle cx="7" cy="7" r="6.5" stroke="currentColor" />
    <rect x="6" y="6" width="2" height="5" fill="currentColor" />
    <circle cx="7" cy="4" r="1" fill="currentColor" />
  </svg>
</template>

<script lang="ts" setup>
const props = defineProps({
  iconClasses: { type: String, default: "text-blue-70" }, // e.g. "fill-black"
})
</script>
