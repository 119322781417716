import { RouteLocation, RouteRecordRaw } from "vue-router"
import { AppPortal } from "@common/models/models"
import MarketplaceLayout from "./MarketplaceLayout.vue"
import AppLayout from "@/components/layout/AppLayout.vue"
import scalarFromVectorOrScalar from "@/utils/scalarFromVectorOrScalar"
import MarketplaceView from "./MarketplaceView.vue"
import PortfoliosSection from "./components/PortfoliosSection.vue"
import ListingsSection from "./components/ListingsSection.vue"
import ListingView from "./ListingView.vue"
import ListingCheckoutView from "./ListingCheckoutView.vue"
import ListingPurchaseConfirmationView from "./ListingPurchaseConfirmationView.vue"
import StoryView from "./StoryView.vue"
import SearchView from "./SearchView.vue"
import { getEnvironment } from "@/environment"
import { authorizedRoute } from "@/services/auth/authorized.route-guard"

const { WWW_BASE_URL } = getEnvironment()

const listingIdProp = (route: RouteLocation) => ({ listingId: parseInt(scalarFromVectorOrScalar(route.params.listingId)) })
const paymentIdProp = (route: RouteLocation) => ({ paymentId: parseInt(scalarFromVectorOrScalar(route.params.paymentId)) })
const storyIdProp = (route: RouteLocation) => ({
  storyId: parseInt(scalarFromVectorOrScalar(route.params.storyId)),
  procurementOptions: !!route.query.procurementOptions,
})

export const marketRoutes: Array<RouteRecordRaw> = [
  {
    path: "/openeac-exchange/",
    name: "wc-openeac-exchange",
    beforeEnter: () => {
      location.href = `${WWW_BASE_URL}/openeac-exchange`
    },
    component: MarketplaceView,
    meta: { layout: MarketplaceLayout, appPortal: AppPortal.Enterprise, title: "OpenEAC Exchange" },
  },
  {
    path: "/openeac-exchange/portfolios",
    name: "wc-portfolios",
    component: PortfoliosSection,
    meta: { layout: MarketplaceLayout, appPortal: AppPortal.Enterprise, title: "Marketplace" },
  },
  {
    path: "/openeac-exchange/listings",
    name: "wc-listings",
    component: ListingsSection,
    meta: { layout: AppLayout, appPortal: AppPortal.Enterprise, title: "Listings" },
  },
  {
    path: "/openeac-exchange/listings/:listingId",
    name: "wc-listing",
    component: ListingView,
    props: listingIdProp,
    meta: { layout: AppLayout, appPortal: AppPortal.Enterprise, title: "Listing" },
  },
  {
    path: "/openeac-exchange/listings/checkout",
    name: "wc-listing-checkout",
    component: ListingCheckoutView,
    meta: { layout: AppLayout, appPortal: AppPortal.Enterprise, title: "Checkout" },
  },
  {
    path: "/openeac-exchange/listings/confirmation/:paymentId",
    name: "wc-listing-confirmation",
    component: ListingPurchaseConfirmationView,
    props: paymentIdProp,
    meta: { layout: AppLayout, appPortal: AppPortal.Enterprise, title: "Purchase Confirmation" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/openeac-exchange/stories/:storyId",
    name: "wc-story",
    component: StoryView,
    props: storyIdProp,
    meta: { layout: MarketplaceLayout, appPortal: AppPortal.Enterprise, title: "Story" },
  },
  {
    path: "/openeac-exchange/search",
    name: "wc-openeac-exchange-search",
    component: SearchView,
    meta: { layout: MarketplaceLayout, appPortal: AppPortal.Enterprise, title: "Marketplace - Search" },
  },
  // Redirects for existing marketplace routes
  {
    path: "/marketplace/",
    name: "wc-marketplace",
    beforeEnter: () => {
      location.href = `${WWW_BASE_URL}/openeac-exchange`
    },
    component: MarketplaceView,
    meta: { layout: MarketplaceLayout, appPortal: AppPortal.Enterprise, title: "OpenEAC Exchange" },
  },
  {
    path: "/marketplace/listings/:listingId",
    name: "wc-marketplace-listing",
    redirect: { name: "wc-listing" },
  },
  {
    path: "/marketplace/stories/:storyId",
    name: "wc-marketplace-story",
    redirect: { name: "wc-story" },
  },
  {
    path: "/marketplace/search",
    name: "wc-marketplace-search",
    redirect: { name: "wc-openeac-exchange-search" },
  },
]
