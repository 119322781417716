<template>
  <div v-if="calendarDialogIsVisible" :id="props.formContext" class="fixed inset-0 z-50">
    <div class="fixed inset-0 bg-black opacity-40" @click="closeCalendarDialog"></div>
    <div class="wc-page-container pointer-events-none flex min-h-screen w-full items-center justify-center">
      <div class="relative size-full px-2 md:px-0">
        <div class="pointer-events-auto mx-auto w-full max-w-xl rounded-lg bg-highlight-pastelazure p-4 md:p-6">
          <div class="ph-no-capture flex justify-end">
            <button class="right-0 top-0 z-10 size-8" @click="closeCalendarDialog">
              <CloseIcon icon-classes="fill-black" />
            </button>
          </div>
          <div>
            <h2 class="text-heading-4 -mt-2 text-center md:mb-2">{{ title }}</h2>
            <p class="text-body-2 mt-8 text-center">{{ promptText }}</p>
            <div class="flex justify-center">
              <ButtonCTA
                button-classes="mt-6 md:mt-8 mb-2 md:mb-4"
                theme="light"
                :is-filled="true"
                type="anchor"
                href="https://calendar.app.google/mjNvcazS358gskFQ8"
                target="_blank"
                >Schedule a call</ButtonCTA
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue"
import posthog from "posthog-js"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import CloseIcon from "@/components/icon/CloseIcon.vue"

type Props = {
  formContext?: string
  promptText?: string
  title?: string
}
const props = withDefaults(defineProps<Props>(), {
  formContext: "Footer",
  title: "Set up a call",
  promptText: "Schedule a call with our team and learn more.",
})

const calendarDialogIsVisible = ref(false)

const openCalendarDialog = () => {
  calendarDialogIsVisible.value = true
}
const closeCalendarDialog = () => {
  calendarDialogIsVisible.value = false
  posthog.capture(`Closed Calendar Dialog opened from ${props.formContext}`)
}
defineExpose({ openCalendarDialog, closeCalendarDialog })
</script>
