<template>
  <div class="wc-page-container text-body-2 my-16 w-full">
    <section class="flex size-full flex-col flex-wrap items-start justify-center gap-5 md:flex-nowrap">
      <div class="flex w-full flex-col items-center">
        <div class="flex flex-col items-center px-4">
          <div class="mt-6 flex flex-col items-center gap-8 text-center md:flex-row md:text-left">
            <div>
              <h4 class="text-heading-4">Redirecting to the WattCarbon Marketplace</h4>
            </div>
          </div>
          <div class="mt-16 flex justify-center">
            <div>
              <ButtonCTA width="full" button-classes="bg-blue-90 text-white hover:bg-blue-40 mt-10" type="anchor" :href="WWW_BASE_URL"
                >Go Now</ButtonCTA
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import { getEnvironment } from "@/environment"
import { setCanonicalUrl } from "@/metatags"

const { WWW_BASE_URL } = getEnvironment()

setCanonicalUrl(WWW_BASE_URL)
</script>
