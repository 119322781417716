<template>
  <WcModal
    class="contact-us-form"
    :is-open="contactFormIsVisible"
    :show-close-button="true"
    :header="title"
    size="medium"
    @update:is-open="closeContactForm">
    <template #panel>
      <div v-if="description && !result && !error" class="text-subheading-1">
        {{ description }}
      </div>
      <div :id="props.formContext">
        <div v-if="result">
          <p class="text-body-2">Thank you! {{ successMessage }}</p>
        </div>
        <div v-else>
          <form ref="formRef" class="text-body-1" @submit.prevent="onSubmit">
            <fieldset class="ph-no-capture mt-2 block md:mt-4">
              <label class="mb-2" for="name">Name*</label>
              <input
                id="name"
                v-model="name"
                type="text"
                name="name"
                required
                class="text-body-2 block w-full rounded-md border border-blue-90 p-4"
                @click="captureNameEvent" />
            </fieldset>
            <fieldset class="ph-no-capture mt-2 block md:mt-4">
              <label class="mb-2" for="company">Company</label>
              <input
                id="company"
                v-model="company"
                type="text"
                name="company"
                class="text-body-2 block w-full rounded-md border border-blue-90 p-4"
                @click="captureCompanyEvent" />
            </fieldset>
            <fieldset class="ph-no-capture mt-2 block md:mt-4">
              <label class="mb-2" for="email">Email*</label>
              <input
                id="email"
                v-model="email"
                type="email"
                name="email"
                required
                class="text-body-2 block w-full rounded-md border border-blue-90 p-4"
                @click="captureEmailEvent" />
            </fieldset>
            <fieldset class="ph-no-capture mt-2 block md:mt-4">
              <label class="mb-2" for="message">Message*</label>
              <textarea
                id="message"
                v-model="message"
                name="message"
                class="text-body-2 block w-full rounded-md border border-blue-90 p-4"
                @click="captureMessageEvent"></textarea>
            </fieldset>
            <slot name="additionalFields"></slot>
          </form>
        </div>
        <div v-if="error">
          <div class="mb-4 flex rounded-lg bg-error-light p-4 text-sm text-error" role="alert">
            <span class="font-medium">{{ error }}</span>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <ButtonCTA v-if="!result" theme="light" @click="contactFormIsVisible = false">Cancel</ButtonCTA>
      <ButtonCTA v-if="!result" button-classes="bg-highlight" @click="formRef?.requestSubmit()">Submit</ButtonCTA>
      <ButtonCTA v-if="result" theme="light" is-filled @click="closeContactForm">Close</ButtonCTA>
    </template>
  </WcModal>
</template>

<script lang="ts" setup>
import _ from "lodash"
import { ref } from "vue"
import posthog from "posthog-js"
import { getEnvironment } from "@/environment"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import WcModal from "@/components/WcModal.vue"

const { API_BASE_URL } = getEnvironment()

type Props = {
  description?: string
  formContext?: string
  formContextMetadata?: Record<string, string | undefined>
  successMessage?: string
  title?: string
  endpoint?: string
}
const props = withDefaults(defineProps<Props>(), {
  formContext: "Footer",
  title: "Contact Us",
  successMessage: "A member of the WattCarbon team will be in touch with you soon.",
  endpoint: "/contact-us",
})

defineSlots<{ additionalFields?: () => any }>()

const contactFormIsVisible = ref(false)
const name = ref("")
const company = ref("")
const email = ref("")
const message = ref("")

const openContactForm = () => {
  contactFormIsVisible.value = true
}
const closeContactForm = () => {
  resetModal()

  contactFormIsVisible.value = false
  posthog.capture(`Closed "Contact" form opened from ${props.formContext}`)
}
defineExpose({ openContactForm, closeContactForm })

const captureNameEvent = () => posthog.capture('Clicked on the "Name" field - Contact Us Form')
const captureCompanyEvent = () => posthog.capture('Clicked on the "Company" field - Contact Us Form')
const captureEmailEvent = () => posthog.capture('Clicked on the "Email" field - Contact Us Form')
const captureMessageEvent = () => posthog.capture('Clicked on the "Message" field - Contact Us Form')

const error = ref("")
const result = ref(false)
const formRef = ref()

const defaultFieldKeys = ["name", "company", "email", "message"]

const getAdditionalFormFields = () => {
  const formData = new FormData(formRef.value)
  const fields = {} as Record<string, string>
  formData.forEach((value, key) => {
    if (typeof value === "string") {
      fields[key] = value
    }
  })
  return _.omit(fields, defaultFieldKeys)
}

const resetAdditionalFormFields = () => {
  const formData = new FormData(formRef.value)
  const keys = {} as Record<string, boolean>
  formData.forEach((_value, key) => {
    keys[key] = true
  })
  const additionalFieldKeys = _.omit(keys, defaultFieldKeys)
  Object.keys(additionalFieldKeys).forEach((key) => formData.delete(key))
}

const resetModal = () => {
  name.value = ""
  company.value = ""
  email.value = ""
  message.value = ""

  result.value = false
  error.value = ""
}

const constructAdditionalFieldsMessage = () => {
  const additionalFields = getAdditionalFormFields()
  if (Object.keys(additionalFields).length === 0) {
    return ""
  }
  return Object.entries(additionalFields)
    .map(([key, value]) => {
      return `${key}: ${value}`
    })
    .join("\n")
}

async function onSubmit() {
  error.value = ""
  result.value = false
  try {
    const additionalFieldsMessage = constructAdditionalFieldsMessage()
    const formContextMetadataMessage = props.formContextMetadata
      ? Object.entries(props.formContextMetadata)
          .map(([key, value]) => `${key}: ${value}`)
          .join("\n")
      : ""
    const messagePieces = [message.value, "\nMetadata:", `Form context: ${props.formContext}`, additionalFieldsMessage, formContextMetadataMessage]
    const messageStr = messagePieces.filter(Boolean).join("\n")
    const body = {
      name: name.value ?? "",
      company: company.value ?? "",
      email: email.value ?? "",
      message: messageStr,
    }
    await fetch(`${API_BASE_URL}${props.endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
    result.value = true
    message.value = ""

    resetAdditionalFormFields()
    posthog.capture(`Submitted "Contact Us" form opened from ${props.formContext}`)
  } catch (ex) {
    console.log(ex)
    error.value = "Sorry, we're having an issue with our contact form. Please try again."
    posthog.capture('Error when trying to submit "Contact Us" form')
  }
}
</script>
