<template>
  <AppPage>
    <AppPageHeader :backlink-route="{ name: 'wc-assets' }" backlink-text="Back to Assets">Upload asset metadata to WEATS</AppPageHeader>
    <AppPageContent>
      <div class="max-w-[780px]">
        <form class="flex flex-col gap-2" @submit.prevent="uploadFile">
          <p>
            Assets' metadata must be uploaded as a CSV file, according to the
            <a :href="ASSET_TEMPLATE_URL" class="text-hyperlink" target="_blank">WEATS Asset Template</a>. You may do this in one of the following
            ways:
          </p>
          <p class="text-subheading-1 mt-4">Simple (Recommended for uploads of 1-100 assets)</p>
          <p>
            Make a copy of the <a :href="ASSET_TEMPLATE_URL" class="text-body-1-link" target="_blank">WEATS Asset Template</a>. Input your assets'
            data in the relevant sheet, and export the completed sheet as a CSV file.
          </p>

          <p class="text-subheading-1 mt-4">Advanced (Recommended for uploads of 100+ assets)</p>
          <p>
            Create a CSV file from your database that matches the formatting of the relevant sheet in
            <a :href="ASSET_TEMPLATE_URL" class="text-body-1-link" target="_blank">WEATS Asset Template</a>. Make sure that all column labels and
            input formats match those in the template.
          </p>
          <p class="mt-4">
            Note: After uploading your assets' metadata, you will also need to upload documentation to validate that the asset exists. For example,
            this documentation may be an invoice for the asset's installation, showing the asset's address.
          </p>
          <div class="mt-8 border-t border-neutral-30 pt-8">
            <label>
              Select the type of assets you want to upload *
              <WcDropdown v-model="assetKind" class="my-2" name="assetKind" :options="kindOptions" inset-label="Resource Type" required />
            </label>
            <label class="my-8">
              Upload the completed WEATS Asset Template as a CSV file *
              <WcInputFile v-model="uploadedFiles" required name="uploadedFiles" accept=".csv" :max-file-size="MAX_FILE_SIZE" class="mt-2" />
            </label>
            <WcButton
              :icon="isUploading ? 'spinner' : undefined"
              text="Upload"
              type="submit"
              :is-disabled="isUploading || uploadedFiles == null || uploadedFiles?.length === 0 || assetKind == null" />
          </div>
        </form>
      </div>
    </AppPageContent>
  </AppPage>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useRouter } from "vue-router"
import { AssetKind } from "@common/models/asset"
import { ASSET_KIND } from "@common/models/asset"
import type { SelectOption } from "@/components/input"
import { WcDropdown, WcInputFile } from "@/components/input"
import { AppPage, AppPageContent, AppPageHeader } from "@/components/layout"
import { WcButton } from "@/components/button"
import type { AssetUploadResult } from "@/modules/asset/asset.service"
import { useAssetService } from "@/services/service-container"
import { parseCsvFile } from "@/utils/csv"
import { useAssetStore } from "./asset.state"
import { formatValidationResultsForReview } from "./UploadAssetsView.utils"

const ASSET_TEMPLATE_URL = "https://docs.google.com/spreadsheets/d/1HOBMqLm1Sjr1mNFA495rbCSmc1c09dqonCz4L2F9Kho/view"
const MAX_FILE_SIZE = 1 * 1024 * 1024 // 1 MB

const kindOptions = Object.entries(ASSET_KIND).map(([value, label]) => ({ label, value }))

const assetService = useAssetService()
const router = useRouter()
const assetStore = useAssetStore()

const assetKind = ref<SelectOption<AssetKind> | null>(null)
const uploadedFiles = ref<FileList | null>(null)
const isUploading = ref<boolean>(false)

const uploadFile = async () => {
  const fileData = uploadedFiles.value?.[0]

  if (!fileData || !assetKind.value) {
    console.error("No file or asset kind selected")
    return
  }

  isUploading.value = true
  assetStore.assetSubmissionKind = assetKind.value.value
  assetStore.assetSubmissionFile = fileData
  assetStore.assetSubmissionGlobalErrors = []
  assetStore.assetSubmissionGlobalWarnings = []
  assetStore.assetSubmissionRows = []

  // Parse the CSV file
  let csvRows
  try {
    csvRows = await parseCsvFile(fileData)
  } catch (error) {
    if (error instanceof Error) {
      assetStore.assetSubmissionGlobalErrors = [error.message]
    }
  }

  // Validate the uploaded assets and persist to store
  let validationResults = [] as AssetUploadResult[]
  try {
    const result = await assetService.validateUploadedAssets(assetKind.value.value, fileData)
    if (Array.isArray(result)) {
      validationResults = result
    } else {
      assetStore.assetSubmissionGlobalErrors = [result.detail]
    }
  } catch (error) {
    if (error instanceof Error) {
      assetStore.assetSubmissionGlobalErrors = [error.message]
    }
  }

  if (validationResults && csvRows) {
    assetStore.assetSubmissionRows = formatValidationResultsForReview(validationResults, csvRows)
    if (assetStore.assetSubmissionGlobalErrors.length === 0 && assetStore.assetSubmissionRows.length === 0) {
      assetStore.assetSubmissionGlobalErrors = ["No valid assets found in the uploaded file"]
    }
  }

  // Reset inputs
  uploadedFiles.value = null
  isUploading.value = false

  router.push({ name: "wc-assets-review-upload" })
}
</script>
